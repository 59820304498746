import React, { useEffect, useState } from "react";
import { navigationMenu } from "shared/data/navigation-menu.data";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const RightNavigation = (props) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    //check if path exists
    function checkPath(item) {
      if (item.hasChildren) {
        return _.find(
          item.children,
          (url) => url.redirectPath === location.pathname
        );
      } else {
        return location.pathname.includes(item.redirectPath) ? item : null;
      }
    }

    const currentPath = _.reduce(
      navigationMenu,
      (acc, item) => {
        return acc || checkPath(item);
      },
      undefined
    );
    if (currentPath) {
      setActiveRoute(currentPath);
    }
  }, [location]);

  //create an object of clicked Sub Menu Items and update their status
  const [isSubMenuClicked, setisSubMenuClicked] = useState([
    {
      linkId: -1,
      isClicked: false,
    },
  ]);

  //get defaultRoute
  const defaultRoute = navigationMenu.find((item) => item.isDefaultRoute);

  //update Active Route obj
  const [activeRoute, setActiveRoute] = useState(defaultRoute);

  //for SubMenu Click Logic
  function isSubNavClicked(id) {
    //toggle Sub Menu
    //if sub-menu has been opened before; toggle open /close behavior
    if (isSubMenuClicked.some((item) => item.linkId === id)) {
      isSubMenuClicked.find((item) => item.linkId === id).isClicked =
        !isSubMenuClicked.find((item) => item.linkId === id).isClicked;

      setisSubMenuClicked([...isSubMenuClicked]);
    } else {
      //add to the list of submenu with clicked as true
      //first time any sub-menu being opened
      setisSubMenuClicked([
        ...isSubMenuClicked,
        {
          linkId: id,
          isClicked: true,
        },
      ]);
    }
  }

  function navMenuItemClick(menuItem) {
    //on Clicking any Nav Item
    //change active route item
    setActiveRoute(menuItem);
    //if items contain sub-menus, open / close sub menu logic
    if (menuItem.hasChildren) {
      isSubNavClicked(menuItem.id);
    } else {
      history.push(menuItem.redirectPath);
      props.toggleNavbar();
    }
  }

  return (
    <div
      className={`webcv-menu-bar ${props.isNavBarOpen ? "webcv-active" : ""}`}
    >
      {/*Left Navigation add active class on sub-menu open*/}
      <div className={`webcv-menu-bar-frame`}>
        <div className="webcv-menu-bar-header">
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a
            className={`webcv-menu-bar-btn ${
              props.isNavBarOpen ? "webcv-active" : ""
            } ${props.isDisabled ? "webcv-disabled" : ""}`}
            role="button"
            onClick={props.toggleNavbar}
          >
            {/*Burger Icon */}
            <span></span>
          </a>
        </div>
        {/*Current Route Active */}
        <div className="webcv-current-page" onClick={props.toggleNavbar}>
          {activeRoute?.name}
        </div>
        {/*Navbar Menu */}
        <div className="webcv-scroll-frame">
          <nav>
            {/* Populate Menu Items */}

            {/* If Items have submenu; call SubMenuItem component*/}

            <ul className="main-menu">
              {navigationMenu.map((menuItem) =>
                menuItem.hasChildren ? (
                  <SubMenuItem
                    key={menuItem.id}
                    isSubMenuClicked={isSubMenuClicked}
                    navMenuItemClick={navMenuItemClick}
                    menuItemObj={menuItem}
                  />
                ) : (
                  <MenuItem
                    key={menuItem.id}
                    menuItem={menuItem}
                    navMenuItemClick={navMenuItemClick}
                  />
                )
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

//simple menu item that has no children
const MenuItem = (props) => {
  return (
    <li key={props.menuItem.id} className={`menu-item`}>
      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
      <a role="button" onClick={() => props.navMenuItemClick(props.menuItem)}>
        {props.menuItem.name}
      </a>
    </li>
  );
};

//sub  menu item that will have children
const SubMenuItem = (props) => {
  //function to check sub-menu item is in which state
  function isSubItemOpen(allItemsObj, currentSubItem) {
    //if item is open
    if (
      allItemsObj.some((item) => {
        return item.linkId === currentSubItem.id && item.isClicked;
      })
    ) {
      return true;
    }
    return false;
  }
  return (
    <li
      key={props.menuItemObj.id}
      className={`menu-item menu-item-has-children`}
    >
      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
      <a
        role="button"
        onClick={() => props.navMenuItemClick(props.menuItemObj)}
      >
        <span className="child-name"> {props.menuItemObj.name}</span>

        <FontAwesomeIcon
          icon={
            isSubItemOpen(props.isSubMenuClicked, props.menuItemObj)
              ? faChevronDown
              : faChevronRight
          }
          className="child-chevron-right"
        />
      </a>
      {/*Check for respective sub menu item open / close state */}
      <ul
        className={`sub-menu ${
          isSubItemOpen(props.isSubMenuClicked, props.menuItemObj)
            ? "webcv-active"
            : ""
        }`}
      >
        {props.menuItemObj.children.map((subMenuItem) => (
          <MenuItem
            key={subMenuItem.id}
            menuItem={subMenuItem}
            navMenuItemClick={props.navMenuItemClick}
          />
        ))}
      </ul>
    </li>
  );
};

export default RightNavigation;
