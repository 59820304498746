export const educationTimeline = [
  {
    id: 1,
    schoolName: "QUT",
    schoolCountry: "Australia",
    schoolMarks: "6.69 GPA on a scale of 7",
    schoolYear: "Feb 2019 - Dec 2020",
    schoolHighlights: [
      {
        id: 1.1,
        highlightText:
          "Masters in Information Technology (Computer Science Major)",
      },
    ],
  },
  {
    id: 2,
    schoolName: "The Northcap University",
    schoolMarks: "8.44 CGPA on a scale of 10",
    schoolCountry: "India",
    schoolYear: "July 2012 - June 2016",
    schoolHighlights: [
      {
        id: 2.1,
        highlightText: "Bachelors in Information Technology",
      },
    ],
  },
];
