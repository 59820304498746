export const artsListData = [
  { id: 11, imagePath: `11.jpg` },
  { id: 10, imagePath: `10.jpg` },
  { id: 9, imagePath: `9.jpg` },
  { id: 8, imagePath: `8.jpg` },
  { id: 7, imagePath: `7.jpg` },
  { id: 6, imagePath: `6.jpg` },
  { id: 5, imagePath: `5.jpg` },
  { id: 4, imagePath: `4.jpg` },
  { id: 3, imagePath: `3.jpg` },
  { id: 2, imagePath: `2.jpg` },
  { id: 1, imagePath: "1.jpg" },
];
