/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  socialMediaPlatformsList,
  socialMediaLinks,
} from "shared/data/profile-details-skills.data.js";
import {
  faLinkedin,
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faUser,
  faAt,
  faEnvelope,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { send } from "@emailjs/browser";
import { contactServiceInfo } from "shared/data/contact-page.data";

const ContactPage = (props) => {
  const socialMediaIconSet = [
    { platform: socialMediaPlatformsList.LinkedIn, icon: faLinkedin },
    { platform: socialMediaPlatformsList.Youtube, icon: faYoutube },
    { platform: socialMediaPlatformsList.Facebook, icon: faFacebook },
    { platform: socialMediaPlatformsList.Instagram, icon: faInstagram },
  ];

  const contactFormObj = {
    name: null,
    email: null,
    message: null,
  };
  const [contactForm, setContactForm] = useState(contactFormObj);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [formSubmitted, setformSubmitted] = useState(false);

  function ValidateEmail(email) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  const formValidation = () => {
    let errors = [];
    setErrorMessages([]);
    const isNameValid = contactForm.name && contactForm.name !== "";
    const isMessageValid =
      contactForm.message &&
      (contactForm.message !== "" || contactForm.message.length > 5);
    if (!isNameValid) {
      errors.push("Please Enter a Name.");
    }
    if (!ValidateEmail(contactForm.email)) {
      errors.push("Please Enter a valid Email ID.");
    }
    if (!isMessageValid) {
      errors.push("Please Enter a valid Message.");
    }
    if (errors.length > 0) {
      setShowErrors(true);
      setErrorMessages(errors);
    } else {
      setShowErrors(false);
      setErrorMessages([]);
      setformSubmitted(true);
      const dataToSend = {
        from_name: contactForm.name,
        message: contactForm.message,
        reply_to: contactForm.email,
      };
      send(
        contactServiceInfo.serviceId,
        contactServiceInfo.templateId,
        dataToSend,
        contactServiceInfo.integrationId
      )
        .then(() => {
          setformSubmitted(true);
        })
        .catch((err) => {
          errors.push(
            "The email could not be sent. Please contact through a different channel."
          );
          setShowErrors(true);
          setErrorMessages(errors);
          setformSubmitted(false);
        });
    }
  };
  return (
    <>
      <div
        className={`webcv-content ${props.isDisabled ? "webcv-active" : ""}`}
      >
        <div className="webcv-curtain" onClick={props.closeAllPanels}></div>
        <div className="webcv-top-bg">
          <div className="webcv-top-bg-overlay"></div>
        </div>
        <Scrollbar
          damping={0.2}
          renderByPixels={true}
          continuousScrolling={true}
          className="webcv-scroll-frame"
        >
          <div className="container-fluid pb-5">
            {" "}
            <div className="row p-30-0">
              <div className="col-lg-12">
                <div className="webcv-section-title">
                  <div className="webcv-title-frame">
                    <h4>Contact</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="webcv-a webcv-card">
                  {/* Social Media Links */}
                  <div className="row">
                    {socialMediaLinks.map((item) => (
                      <div key={item.id} className="col-3 text-center">
                        <a
                          rel="noopener noreferrer"
                          href={item.profileLink}
                          target="_blank"
                        >
                          {socialMediaIconSet
                            .filter(
                              (arrItem) => arrItem.platform === item.platform
                            )
                            .map((filterItem) => (
                              <FontAwesomeIcon
                                key={item.id}
                                icon={filterItem.icon}
                                className="bigIcon"
                              />
                            ))}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Contact Form */}
              <div className="col-lg-12">
                <div className="webcv-section-title">
                  <div className="webcv-title-frame">
                    <h4>Get in touch</h4>
                  </div>
                </div>
                <div className="webcv-a webcv-card">
                  <form
                    id="form"
                    className={`webcv-contact-form ${
                      formSubmitted ? "low-opacity" : ""
                    }`}
                  >
                    <div className="webcv-form-field">
                      <input
                        id="name"
                        className="webcv-input"
                        type="text"
                        placeholder="Name"
                        required
                        onChange={(e) =>
                          setContactForm({
                            ...contactForm,
                            name: e.target.value,
                          })
                        }
                        disabled={formSubmitted}
                      />
                      <label htmlFor="name">
                        <FontAwesomeIcon
                          icon={faUser}
                          className="webcv-w-chevron-tr"
                        />
                      </label>
                    </div>
                    <div className="webcv-form-field">
                      <input
                        id="email"
                        className="webcv-input"
                        type="email"
                        placeholder="Email"
                        required
                        onChange={(e) =>
                          setContactForm({
                            ...contactForm,
                            email: e.target.value,
                          })
                        }
                        disabled={formSubmitted}
                      />
                      <label htmlFor="email">
                        <FontAwesomeIcon
                          icon={faAt}
                          className="webcv-w-chevron-tr"
                        />
                      </label>
                    </div>
                    <div className="webcv-form-field">
                      <textarea
                        id="message"
                        className="webcv-input"
                        placeholder="Message"
                        required
                        onChange={(e) =>
                          setContactForm({
                            ...contactForm,
                            message: e.target.value,
                          })
                        }
                        disabled={formSubmitted}
                      ></textarea>
                      <label htmlFor="message">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="webcv-w-chevron-tr"
                        />
                      </label>
                    </div>
                    <div className="webcv-submit-frame">
                      <button
                        disabled={formSubmitted}
                        className="webcv-btn webcv-btn-md webcv-submit"
                        type="button"
                        onClick={formValidation}
                      >
                        <span>Send message</span>
                      </button>
                    </div>
                  </form>
                  <div className="row pt-3">
                    <div className="col-lg-12">
                      <div
                        className={`${
                          showErrors || formSubmitted
                            ? "webcv-info-message"
                            : ""
                        }`}
                      >
                        {showErrors ? (
                          errorMessages.map((item, index) => {
                            return <ul key={index}>{item}</ul>;
                          })
                        ) : formSubmitted ? (
                          <span className="">
                            Message Sent <FontAwesomeIcon icon={faCheck} />
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>
    </>
  );
};

export default ContactPage;
