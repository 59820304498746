/* eslint-disable jsx-a11y/anchor-is-valid */
import FsLightbox from "fslightbox-react";
import React, { useState } from "react";
import { photographyListData } from "shared/data/photography-page.data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faHeart } from "@fortawesome/free-solid-svg-icons";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay, Navigation]);

const params = {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 30,
  speed: 1400,
  autoplay: { delay: 2000, pauseOnMouseEnter: true },
  mousewheel: true,
  breakpoints: {
    2400: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 1,
    },
  },
};

const PhotographyPage = (props) => {
  const photographyData = photographyListData;
  //project image toggler opens avatar lightbox
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });
  const photographySources = photographyData.map((data) => {
    return require(`assets/img/photography/${data.imagePath}`).default;
  });
  function openLightBoxSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }
  return (
    <>
      {" "}
      <div
        className={`webcv-content ${props.isDisabled ? "webcv-active" : ""}`}
      >
        <div className="webcv-curtain" onClick={props.closeAllPanels}></div>{" "}
        <div className="webcv-top-bg">
          <div className="webcv-top-bg-overlay"></div>
        </div>
        <div className="container-fluid webcv-scroll-frame">
          {" "}
          <div className="row p-30-0">
            <div className="col-lg-12">
              <div className="webcv-section-title">
                <div className="webcv-title-frame">
                  <h4>Recent Photographs</h4>
                </div>
                <div className="webcv-right-frame">
                  <div className="webcv-project-category">
                    Handmade with{" "}
                    <FontAwesomeIcon
                      className="webcv-item-hover"
                      icon={faHeart}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <Swiper
                {...params}
                pagination={{
                  type: "fraction",
                }}
                navigation={true}
                className={`swiper-container webcv-works`}
              >
                {photographyData.map((item, index) => (
                  <SwiperSlide key={item.id}>
                    <div
                      className="webcv-a
                                webcv-portfolio-item-frame
                                webcv-horizontal"
                    >
                      <a
                        role="button"
                        onClick={() => openLightBoxSlide(index + 1)}
                        className="d-contents"
                      >
                        <img
                          src={
                            require(`assets/img/photography/${item.imagePath}`)
                              .default
                          }
                          alt="PhotographyImage"
                        />
                        <FontAwesomeIcon
                          className="webcv-item-hover"
                          icon={faExpand}
                        />
                      </a>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={photographySources}
          slide={lightboxController.slide}
        />
      </div>
    </>
  );
};

export default PhotographyPage;
