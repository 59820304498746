export const ParticleJsConfiguration = {
  particles: {
    color: {
      value: "#FFC107",
    },
    number: {
      value: 160,
      density: {
        enable: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        speed: 4,
        size_min: 0.3,
      },
    },
    line_linked: {
      enable: false,
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: "attract",
      },
      onclick: {
        enable: true,
        mode: "attract",
      },
    },
    modes: {
      bubble: {
        distance: 250,
        duration: 2,
        size: 0,
        opacity: 0,
      },
      repulse: {
        distance: 400,
        duration: 4,
      },
    },
  },
};
