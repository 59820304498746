import { projectsListData } from "shared/data/projects-page.data";

export function getProjects() {
  return projectsListData;
}

export function getProjectDetails(id) {
  return getProjects().filter((project) => {
    return project.projectID === id;
  });
}

export function getTotalProjects() {
  return projectsListData.length;
}

export function projectDetailsExist(id) {
  return getProjectDetails(id)[0].hasDetails;
}
