export const languageSkills = [
  { id: 1, skillName: "Vue.js", progressLevel: 90 },
  { id: 2, skillName: "Angular 12", progressLevel: 80 },
  { id: 3, skillName: "React", progressLevel: 70 },
];

export const otherSkills = [
  { id: 1, skillName: "HTML-5", progressLevel: 90 },
  { id: 2, skillName: "CSS/SCSS", progressLevel: 95 },
  { id: 3, skillName: "Typescript", progressLevel: 85 },
  { id: 4, skillName: "Javascript", progressLevel: 75 },
  { id: 5, skillName: "Python", progressLevel: 60 },
];

export const knowledgeList = [
  {
    id: 1,
    skillTitle: "State Management",
    skillName: "Vuex, NGRX, Redux",
  },
  {
    id: 2,
    skillTitle: "Frameworks",
    skillName: "Bootstrap, Ionic, Tailwind, single-spa",
  },
  {
    id: 5,
    skillTitle: "Designing",
    skillName: "Photoshop, Figma",
  },
];

export const socialMediaPlatformsList = {
  Youtube: "Youtube",
  Facebook: "Facebook",
  Instagram: "Instagram",
  LinkedIn: "LinkedIn",
};

export const socialMediaLinks = [
  {
    id: 1,
    platform: socialMediaPlatformsList.LinkedIn,
    profileLink: "https://www.linkedin.com/in/palashchhabra/",
  },
  {
    id: 2,
    platform: socialMediaPlatformsList.Instagram,
    profileLink: "https://instagram.com/palashchhabra",
  },
  {
    id: 3,
    platform: socialMediaPlatformsList.Facebook,
    profileLink: "https://www.facebook.com/palash.chhabra.5",
  },
  {
    id: 4,
    platform: socialMediaPlatformsList.Youtube,
    profileLink: "https://www.youtube.com/channel/UChOSydilaSgEG65w3w8vFMw",
  },
];
