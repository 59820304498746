import React from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import EducationTimeline from "components/history-page/education-timeline/education-timeline";
import ExperienceTimeline from "components/history-page/experience-timeline/experience-timeline";
import historyImage from "assets/img/vector/challenge.png";
const HistoryPage = (props) => {
  return (
    <div className={`webcv-content ${props.isDisabled ? "webcv-active" : ""}`}>
      <div className="webcv-curtain" onClick={props.closeAllPanels}></div>
      <div className="webcv-top-bg">
        <div className="webcv-top-bg-overlay"></div>
      </div>
      <Scrollbar
        damping={0.2}
        renderByPixels={true}
        continuousScrolling={true}
        className="webcv-scroll-frame"
      >
        <div className="container-fluid">
          <div className="row p-30-0">
            <div className="col-xl-7">
              <ExperienceTimeline />
            </div>
            <div className="col-xl-5">
              <EducationTimeline />
              <img
                src={historyImage}
                className="history-image pb-5"
                alt="HistoryImage"
              />
            </div>
          </div>
        </div>
      </Scrollbar>
    </div>
  );
};

export default HistoryPage;
