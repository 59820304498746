import React from "react";
import { awards } from "shared/data/landing-page.data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/free-solid-svg-icons";
const Awards = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          {" "}
          <div className="webcv-section-title">
            {" "}
            <div className="webcv-title-frame">
              <h4>Awards</h4>
            </div>
          </div>
        </div>
        {awards.map((award) => (
          <div key={award.id} className="col-lg-4 col-md-4 d-grid-column">
            <div className="webcv-a webcv-service-icon-box">
              <div className="webcv-service-ib-content">
                <FontAwesomeIcon className="award-icon" icon={faAward} />
                <h5 className="mb-15">{award.awardName}</h5>
                <div className="mb-15">{award.awardDescription}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Awards;
