export const categoryTypes = {
  poem: { id: 1, type: "poem", name: "Poem" },
  musing: { id: 2, type: "musing", name: "Musing" },
  thought: { id: 3, type: "thought", name: "Thought" },
  openLetter: { id: 4, type: "openLetter", name: "Open Letter" },
  Random: { id: 5, type: "Random", name: "Random" },
};

export const writingListData = [
  {
    id: 14,
    writeUpId: 14,
    writeUpTitle: "Sky",
    writeUpShortDescription: "Admiration for sky",
    writeUpDetails: {
      title: "Sky",
      content: `I’ll keep photographing the sky and the clouds,<br/>
      till they register my sincere admiration and feel compelled to reward me with me a personal cotton candy of snowy wool,<br/>
      one that can morph into the exact shade of my thoughts.<br/>
      So when the next time someone tells me how difficult I am to understand,<br/>
      I can blow that cloud in their direction,<br/>
      and wash away their prejudices, biases and worn out labels of ‘difficult/complex/fictional’ that they pass on to describe me.<br/>`,
      category: categoryTypes.thought.name,
      tags: ["Sky", "Universe"],
    },
  },
  {
    id: 1,
    writeUpId: 1,
    writeUpTitle: "Hope",
    writeUpShortDescription: "Scattered in every crevice of universe",
    writeUpDetails: {
      title: "Hope : Scattered in every crevice of universe",
      content: `See a small plant growing through the cracks of a broken monument,<br/>
      Or a lotus blossoming in brown muddy waters,<br/>
      Or the serenity in the strongest of animals,<br/>
      Or cactus thriving in an arid desert,<br/>
      And realise how hope crawls its way out of the chasm.<br/>
      It’s scattered in every crevice of universe, you see. Look closely, you can find it in your hearts too.<br/>`,
      category: categoryTypes.thought.name,
      tags: ["Hope", "Life"],
    },
  },
  {
    id: 2,
    writeUpId: 2,
    writeUpTitle: "Galaxy",
    writeUpShortDescription: "Thank you for being the canvas to my emotions",
    writeUpDetails: {
      title: "Dear galaxy, Thank you for being the canvas to my emotions!",
      content: `
      On nights when my mind is clouded with unprocessed thoughts, your calm demeanour soothes me with your tinge of purple tangled with the shades of pink. </br>
      In moments, I fall short of hope and crumble like a paper, your infinite patterns and entirety doesn’t let me fall apart. From so far, you’ve always felt so close, thank you for always looking out for me and making this long distance work!
      The blues and silver of you inculcate magic beyond my understanding and your art of absorbing everything fascinates me. The more I try to know you, the more unknowns I am dealt with, yet, you’ve always been far up there, humble and grounded.
      <br/>If some day ever, if I get to, I’d want to be a star inside you, promising never to be a shooting one. Only so that I could glitter in the nights and embrace the peaceful colours of your existence.`,
      category: categoryTypes.openLetter.name,
      tags: ["Universe", "Fiction"],
    },
  },
  {
    id: 3,
    writeUpId: 3,
    writeUpTitle: "Black & White",
    writeUpShortDescription: "I will always love you both unconditionally",
    writeUpDetails: {
      title: "Dear Black & White, I will always love you both unconditionally!",
      content: `Our love triangle isn’t perfect. We started very young and I was too naive to understand your worth and the position you held in my life. It might not have been love at first sight from my side but the two of you always complemented each other and with time, I was mesmerised by your composure; your simplicity. I  have always found it difficult to choose one between you two; one of you is high maintenance while the other demands attention. Yet, every time I see either of you, I lean towards you above all your peers. <br><br>

      The world always wanted me to pick ‘Mr. Blue’ and be wary of ‘Ms. Pink’ and make myself a part of the bias. But you’ve always made me feel different, something the blues and pinks of the world could not. I guess I fell in love with your neutrality. <br><br>
      
      Individually, you get along with most of your friends while combined, you’ve become this power couple everyone in your hue family looks upto. Being in this long relationship, you’ve never let your individuality fade away. Instead, you make your other half look better and have so easily made me a part of this beautiful family.<br><br>
      
      White, you brighten my day by just peeking at me from a distance while being squished between a myriad of colours. Black, you’ve become my go to for everything. Maybe the world still hasn’t accepted you as a colour of equality but the apps on my phone have now started giving me the option to choose you  behind everyone else. <br><br>
      
      I seek more of you. I even throw tantrums at clothing stores if they don’t have enough of you both. You are often undervalued and taken for granted. Yet, you’re always accepting.<br><br>
      
      You taught me that it’s okay to break typical stereotypes and moulds. Though I also have a thing for colour gradients, a classic monochrome that you create is irreplaceable. You are life long companions and I am in awe of your sheer longevity. You’re famous everywhere and yet you’ve always remained grounded. One of you absorbs all the imperfections while the other reflects all the positivity off your fellow mates. Always being Impartial, Unbiased and Just.<br><br>
      
      And for this reason I too will always love you both unconditionally. Always have and Always will.<br><br>
      
      Yours truly,<br>
      A person of colour.`,
      category: categoryTypes.openLetter.name,
      tags: ["Colors", "Black&White"],
    },
  },
  {
    id: 4,
    writeUpId: 4,
    writeUpTitle: "Definitions and Stereotypes",
    writeUpShortDescription: "Its okay to not be a part of them",
    writeUpDetails: {
      title: "Definitions and Stereotypes, its okay to not be a part of them!",
      content: `It’s okay,<br><br>

      Each time, I pick up fancy cosmetic products and decide to pamper my face and skin.<br><br>
      
      Each time, I demand that my eyebrows be threaded or I go for a manicure-pedicure session.<br><br>
      
      Each time, I choose colour pink over blue.<br><br>
      
      Each time, I decide to decorate my room with fairy lights and buntings instead of posters of cars and cricketers.<br><br>
      
      Each time, I have to carry my girl’s handbag on my shoulder, instead of me trying to fit into your idea of a man.<br><br>
      
      It will be okay, if I do all the dirty dishes or mend the wardrobe after I settle down with someone. It wouldn’t make me regressive if I do all the household chores and she’s out late partying with her friends.<br><br>
      
      It should be okay, if I am her boyfriend number eight or she’s my girlfriend number four and we’re still not ready to take it to the next level.<br><br>
      
      It’ll still be okay, if I keep no demands or ambitions about my future or career on some days.<br><br>
      
      It’d still be a beautiful night, if I put myself to sleep with acoustic covers over choosing a drunk night out.<br><br>
      
      No matter how progressive we claim ourselves to be,<br>
      The truth remains,<br>
      We have a completely skewed set of expectations and definitions of boys and girls.<br>
      
      And until we see them in the same light and realise that it’s okay not to fit in,<br>
      All the talk about gender roles and breaking the stereotypes would only be harping on social media.`,
      category: categoryTypes.musing.name,
      tags: ["Gender Equality", "Black&White"],
    },
  },
  {
    id: 5,
    writeUpId: 5,
    writeUpTitle: "2020",
    writeUpShortDescription: "If the year could communicate with us",
    writeUpDetails: {
      title: "2020 : If the year could communicate with us!",
      content: `Dear Humans,<br>
      It hasn’t been the start you would have expected. I gave you a wildfire and then a virus. It only went worse from there as some of the prominent personalities left the Earth. I’d like to explain some of the learnings behind it.<br><br>
      
      At first, Be kind to your planet. The Australian wildfire was just a context of how badly you and the wildlife could be affected if you continue to remain unaware and unapologetic.<br><br>
      
      Second, Be kind to each other. Yes, it has been exemplary by the front line workers putting their lives at risk for others in the pandemic. People coming together and doing their bit, applauding and cheering and so much more. Yet, there are some who’ve made a mockery of the situation by ignoring simple health measures. Rapes and murders are still happening across the globe.<br><br>
      
      Third, Be kind to yourself. The world would discriminate you on the basis of your colour, caste or sex and degrade your worth. But learn to love yourself and treat yourself well; all lives matter and the world cannot possibly lose more lovely people to suicide or hateful killings.<br><br>
      
      Last, Learn from the wildlife. Co-exist if not anything else. Be respectful of each other’s boundaries and continue to support and grow together.<br><br>
      
      Hard times don’t last forever and after all of this you’ll be stronger than you were before.<br><br>
      
      Yours,<br>
      2020`,
      category: categoryTypes.musing.name,
      tags: ["2020", "Coronavirus"],
    },
  },
  {
    id: 6,
    writeUpId: 6,
    writeUpTitle: "Your daily dose of dopamine",
    writeUpShortDescription: "One for the social media people",
    writeUpDetails: {
      title: "Your daily dose of dopamine",
      content: `Sometimes, I wonder.<br>
      What would be 'too much' for you?<br>
      What would happen if you lose the things that you have, how would you distract yourself? How would you fill your emptiness, certainly, no meme could cover that place.<br><br>
      
      I have no idea what order of words would make you breathe a sigh of relief. I wonder how many likes on a post would make you feel worthy.<br><br>
      
      When I look around, I see people like you. People laughing at themselves, cracking jokes about misery. Travelling more and crying secretly because that's the only way they can be loved.<br><br>
      
      For now,<br>
      Go to the mountains and upload stories because you need to tell others that you're alive, breathing life. Change people like uniforms because the Internet told you that every single thing is about self-importance. Don't read books, but instead read flashy captions by people who hide behind fancy profiles. Use relevant hashtags or how will you boast about your tiny struggles and unimportant successes. Post Pictures and share the year updates, maintain your snapchat streak, upload carefully curated insta stories and remember to make fun of anything that remotely tries to pull you out of your fairy tale bubble.<br><br>
      
      You're too cool to feel things. But someday when you do, Google for some self-help quotes, dial a number from thousands of your contacts, pour your heart out to your gang as per your convenience.<br><br>
      
      Upload something. Wait for a minute ot two. Hearts will pour in. People will leave behind random adjectives in comments. And there - you will be back, will your dose of dopamine.
      `,
      category: categoryTypes.thought.name,
      tags: ["Social Media"],
    },
  },
  {
    id: 7,
    writeUpId: 7,
    writeUpTitle: "Of Lights & Legos",
    writeUpShortDescription: "Of Lights & Legos",
    writeUpDetails: {
      title: "Things I am supposed to write about",
      content: `I am supposed to write about things,<br>
      that concern the realities of adulthood,<br>
      Like: love and lust,<br>
      Trust and tears,<br>
      Friends and fears,<br>
      Circumstances and Cyclones,<br>
      Humans and homes,<br>
      Instead, I’d choose to write about,<br>
      Magic and mysteries,<br>
      Wishes and wonders,<br>
      Candies and colours,<br>
      Paper boats and pencils,<br>
      Lights and lego houses,<br>
      Because I still feel the sky humming a melody,<br>
      And the trees shivering like anxious lovers.<br>
      Do you remember or feel any of it anymore?<br>
      I will write so that you remember the bliss,<br>
      I will write so that you do not forget or miss.`,
      category: categoryTypes.musing.name,
      tags: ["Adulthood, Stereotypes"],
    },
  },
  {
    id: 8,
    writeUpId: 8,
    writeUpTitle: "Almost",
    writeUpShortDescription: "One for the social media people",
    writeUpDetails: {
      title: "Your daily dose of dopamine",
      content: `Sometimes, I wonder.<br>
      What would be 'too much' for you?<br>
      What would happen if you lose the things that you have, how would you distract yourself? How would you fill your emptiness, certainly, no meme could cover that place.<br><br>
      
      I have no idea what order of words would make you breathe a sigh of relief. I wonder how many likes on a post would make you feel worthy.<br><br>
      
      When I look around, I see people like you. People laughing at themselves, cracking jokes about misery. Travelling more and crying secretly because that's the only way they can be loved.<br><br>
      
      For now,<br>
      Go to the mountains and upload stories because you need to tell others that you're alive, breathing life. Change people like uniforms because the Internet told you that every single thing is about self-importance. Don't read books, but instead read flashy captions by people who hide behind fancy profiles. Use relevant hashtags or how will you boast about your tiny struggles and unimportant successes. Post Pictures and share the year updates, maintain your snapchat streak, upload carefully curated insta stories and remember to make fun of anything that remotely tries to pull you out of your fairy tale bubble.<br><br>
      
      You're too cool to feel things. But someday when you do, Google for some self-help quotes, dial a number from thousands of your contacts, pour your heart out to your gang as per your convenience.<br><br>
      
      Upload something. Wait for a minute ot two. Hearts will pour in. People will leave behind random adjectives in comments. And there - you will be back, will your dose of dopamine.
      `,
      category: categoryTypes.thought.name,
      tags: ["Social Media"],
    },
  },
  {
    id: 9,
    writeUpId: 9,
    writeUpTitle: "M for Monday",
    writeUpShortDescription: "What if Monday could speak to us?",
    writeUpDetails: {
      title: "M for Monday",
      content: `"Oh God, its Monday!" <br>
      You say it all the time. <br>
      Here I am. <br>
      The first of my 6 other siblings.<br>
      I bring your important meetings, <br>
      And put an end to your long vacations.<br>
      I know you love the one before me and wish I was the same too.<br>
      I'm sorry your coffee feels like decaf,<br>
      I'm sorry the fifth shot from the last night is pounding reminders in your head of why you should've stopped at three.<br>
      I'm sorry you wish you were on a vacation right now.<br>
      I'm sorry your life is miserable,<br>
      I'm sorry your job sucks,<br>
      I'm sorry you'd rather be in bed,<br>
      I'm sorry you hate me,<br>
      But honestly, none of these<br>
      I repeat, none of these<br>
      Is 'my' fault.<br>
      And who gave me the colour blue anyway?`,
      category: categoryTypes.musing.name,
      tags: ["MondayBlues"],
    },
  },
  {
    id: 10,
    writeUpId: 10,
    writeUpTitle: "G for Grey",
    writeUpShortDescription: "What if the colour grey could speak to us?",
    writeUpDetails: {
      title: "G for Grey",
      content: `Offspring of the mystic black and pristine white;<br>
      I hold no qualities of either.<br>
      Torn between the two worlds,<br>
      I'm a state of compromise.<br>
      The opinionated despise me,<br>
      For I never take sides.<br>
      I'm neutral and untrustworthy,<br>
      My shades are labelled as Monochrome.<br>
      The hair on your father's scalp will show,<br>
      How I depict age.<br>
      I'm a winter morning fog,<br>
      I'm the colour of the path of your office drive.<br>
      I painted myself over the clouds that drenched you.<br>
      A drop of sparkle,<br>
      Transforms me into lustrous silver used to embellish your exteriors.<br>
      Perhaps, I m not so dull.`,
      category: categoryTypes.musing.name,
      tags: ["Colours"],
    },
  },
  {
    id: 11,
    writeUpId: 11,
    writeUpTitle: "Y for Yellow",
    writeUpShortDescription: "What if the colour yellow spoke to us?",
    writeUpDetails: {
      title: "Y for Yellow",
      content: `When colors from the spectrum appear<br>
      I comfortably sit between them<br>
      That's probably why they call me The Happy Hue<br>
      Even the smiley stress ball in your  drawer will testify<br>
      I am an uncontainable energy and<br>
      A flickering bulb of ideas<br>
      I separate the friendship roses from the love ones<br>
      I can be as sour as lemons and as sweet as corns<br>
      I put the warmth in fire<br>
      And a cheerful tint in every summer<br>
      Take a look at any sunset,<br>
      You'll see how orange and I make the perfect team<br>
      But with a drop of sparkle<br>
      I turn gold.`,
      category: categoryTypes.musing.name,
      tags: ["Colours"],
    },
  },
  {
    id: 12,
    writeUpId: 12,
    writeUpTitle: "F for Fine",
    writeUpShortDescription: "What if the colour grey could speak to us?",
    writeUpDetails: {
      title: "F for Fine",
      content: `"I'm fine."<br>
      Sounds familiar?<br>
      That's what you tell them.<br>
      That's what they tell you.<br>
      Liars.<br>
      You use me to express all your anger, <br>
      Or to add some sophistication to your dining experience.<br>
      I'm that line; too difficult to differentiate.<br>
      Sometimes, you hide beside me,<br>
      Shove the loudest feelings,<br>
      And the quitest ideas under my shadow.<br>
      With a hope that, I cast a spell of ignorance on you.<br>
      And I do.<br>
      I choke your confessions.<br>
      Hush your wishes, dress your wounds while they're still fresh.<br>
      I'm a paid assassin, you pay to kill you<br>
      While you're busy pretending.<br>
      What a fine thing to do.`,
      category: categoryTypes.musing.name,
      tags: ["Musing"],
    },
  },
  {
    id: 13,
    writeUpId: 13,
    writeUpTitle: "P for Peace",
    writeUpShortDescription: "What is Peace?",
    writeUpDetails: {
      title: "P for Peace",
      content: `When everything seems in place, I sneak in.<br>
      I bring calm, in the chaos.<br>
      I’m in the sound of the droplets falling on a window,<br>
      I’m all white and everything bright.<br>
      I’m in the wedding bells and in the morgue.<br>
      I’m in outer space and deep within.<br>
      I m in The Bhagavad Gita, The Quran, and The Holy Bible,<br>
      I’m in morning prayer and the goodnight wish.<br>
      I’m in everything!<br>
      I’m everything.`,
      category: categoryTypes.musing.name,
      tags: ["Peace"],
    },
  },
];
