import { writingListData } from "shared/data/writing-page.data";

export function getWriteUps() {
  return writingListData;
}

export function getWriteUpDetails(id) {
  return getWriteUps().filter((writeup) => {
    return writeup.writeUpId === id;
  });
}

export function getTotalWriteUps() {
  return writingListData.length;
}
