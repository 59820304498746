import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { ProfileHeader, ProfileDetails } from "shared/components/index";
import { basicInfo } from "shared/data/profile-details-basic-info.data";
import Loading from "shared/components/loading/loading";

const LeftProfilePanel = (props) => {
  return (
    <div
      className={`webcv-info-bar ${
        props.isLeftFrameOpen ? "webcv-active" : ""
      } ${props.hideProfileMenu ? "d-none" : "d-block"}`}
    >
      {/* background */}
      {props.appLoading ? (
        <Loading />
      ) : (
        <div className="webcv-info-bar-frame">
          {/* top header -- mobile view */}
          <div className={`webcv-info-bar-header`}>
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a
              role="button"
              className={`webcv-info-bar-btn ${
                props.isDisabled ? "webcv-disabled" : ""
              }`}
              onClick={props.toggleInfobar}
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </a>
          </div>
          {/* profile top section */}
          <ProfileHeader basicInfo={basicInfo} />
          <ProfileDetails basicInfo={basicInfo} />
        </div>
      )}
    </div>
  );
};

export default LeftProfilePanel;
