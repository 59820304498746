export const routePaths = {
  home: { pathName: "Home", path: "/home" },
  history: { pathName: "History", path: "/history" },
  projects: { pathName: "Projects", path: "/projects" },
  projectDetails: { pathName: "ProjectDetails", path: "/projects/:id" },
  interests: {
    pathName: "Interests",
    path: {
      art: { pathname: "Art Projects", path: "/art" },
      handlettering: { pathname: "Hand Lettering", path: "/handlettering" },
      writeups: { pathname: "Write Ups", path: "/writeups" },
      writeupDetails: { pathName: "WriteupDetails", path: "/writeups/:id" },
      photography: { pathname: "Photography", path: "/photography" },
    },
  },
  contact: { pathName: "Contact", path: "/contact" },
};
