/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import FsLightbox from "fslightbox-react";
import {
  faChevronLeft,
  faChevronRight,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotFoundPage, Recommendations } from "shared/components/index";
import {
  getProjectDetails,
  getTotalProjects,
  projectDetailsExist,
} from "shared/providers/projects-provider";
import { Link, useHistory } from "react-router-dom";
import { projectTypes } from "shared/data/projects-page.data";

const ProjectDetailsPage = (props) => {
  const projectActionType = {
    Previous: "previous",
    Next: "next",
  };
  const history = useHistory();

  //project image toggler opens avatar lightbox
  const [projImageToggler, setProjImageToggler] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const { id } = props.match.params;
  const selectedProjectData = getProjectDetails(Number(id));
  const totalProjects = getTotalProjects();
  const projectData = selectedProjectData[0];
  const isResearchProject =
    projectData.projectType.type === projectTypes.research.type;

  function toggleImageBox() {
    props.hideProfile();
    //open the lightbox
    setProjImageToggler(!projImageToggler);
  }

  function getAnotherProject(actionType, currentProjectID) {
    let newProjectID;
    if (actionType === projectActionType.Previous) {
      newProjectID =
        currentProjectID - 1 < 1 ? totalProjects : currentProjectID - 1;
    } else if (actionType === projectActionType.Next) {
      newProjectID =
        currentProjectID + 1 <= totalProjects ? currentProjectID + 1 : 1;
    }
    if (projectDetailsExist(newProjectID)) {
      return history.push(`/projects/${newProjectID}`);
    } else {
      getAnotherProject(actionType, (currentProjectID = newProjectID));
    }
  }

  return (
    <>
      {projectData && projectData.hasDetails ? (
        <div
          className={`webcv-content ${props.isDisabled ? "webcv-active" : ""}`}
        >
          <div className="webcv-curtain" onClick={props.closeAllPanels}></div>
          <div className="webcv-top-bg">
            <div className="webcv-top-bg-overlay"></div>
          </div>
          {/* lightbox for full screen avatar*/}
          <FsLightbox
            onClose={() => {
              setIsLightboxOpen(false);
              props.hideProfile();
            }}
            toggler={projImageToggler}
            sources={[`${projectData.projectDetails.imageFullPath}`]}
            onOpen={() => {
              setIsLightboxOpen(true);
            }}
          />
          <Scrollbar
            damping={0.2}
            renderByPixels={true}
            continuousScrolling={true}
            className={`webcv-scroll-frame ${isLightboxOpen ? "d-none" : ""}`}
          >
            {/* Project Details Header */}
            <div className="container-fluid">
              <div className="row p-30-0">
                <div className="col-lg-12">
                  {" "}
                  <div className="webcv-section-title">
                    {/* Project Title */}
                    <div className="webcv-title-frame">
                      <h4>{projectData.projectDetails.title}</h4>
                    </div>
                    {/* Project Category */}
                    <div className="webcv-right-frame">
                      <div className="webcv-project-category">
                        {projectData.projectType.name}
                      </div>
                    </div>
                  </div>
                </div>
                {isResearchProject ? (
                  <></>
                ) : (
                  <div className="col-lg-12">
                    <div className="click-to-top webcv-a webcv-project-cover">
                      <a
                        role="button"
                        onClick={toggleImageBox}
                        className="webcv-portfolio-item-frame webcv-horizontal"
                      >
                        <img
                          src={
                            require(`assets/img/projects/headerGif/${projectData.projectDetails.imageThumbnailPath}`)
                              .default
                          }
                          alt="avatar"
                        />
                        <span>Click to watch video!</span>
                        <FontAwesomeIcon
                          className="webcv-item-hover"
                          icon={faExpand}
                        />
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Project Details Description */}
            <div className="container-fluid">
              <div className="row p-30-0">
                <div className="col-lg-12">
                  <div className="webcv-section-title">
                    <div className="webcv-title-frame">
                      <h4>
                        {isResearchProject
                          ? "Research Details"
                          : "Project details"}
                      </h4>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    isResearchProject ? "col-lg-12" : "col-lg-8 d-grid-column"
                  }`}
                >
                  <div className="webcv-a webcv-card webcv-fluid-card">
                    <h5 className="mb-15">
                      {isResearchProject ? "Abstract" : "Description"}
                    </h5>
                    <div className="mb-15 text-justify">
                      {projectData.projectDetails.longDescription}
                    </div>
                    {projectData.projectDetails.deployURL.length ? (
                      <div className="webcv-buttons-frame">
                        <a
                          href={projectData.projectDetails.deployURL}
                          target="_blank"
                          rel="noreferrer"
                          className="webcv-link webcv-color-link webcv-w-chevron"
                        >
                          {isResearchProject
                            ? "View Publication!"
                            : " View Live!"}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {isResearchProject ? (
                  <></>
                ) : (
                  <div className="col-lg-4 d-grid-column">
                    <div className="webcv-a webcv-card">
                      <div className="webcv-table p-15-15">
                        <ul>
                          <li>
                            <h6>Stack:</h6>
                            <span>{projectData.projectDetails.techStack}</span>
                          </li>
                          <li>
                            <h6>Organisation:</h6>
                            <span>
                              {projectData.projectDetails.organisation}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Project Details Recommendations */}
            {projectData.projectDetails.recommendationsExist ? (
              <Recommendations
                data={projectData.projectDetails.recommendations}
              />
            ) : (
              ""
            )}
            <div className="container-fluid">
              <div className="row p-30-0">
                <div className="col-lg-12">
                  <div className="webcv-a webcv-banner">
                    <div className="webcv-banner-overlay height-200">
                      <div className="webcv-banner-title text-center">
                        <h3 className="mb-15">
                          Would like to know more about the project?
                        </h3>
                        {/* Add link */}
                        <Link className="webcv-btn webcv-btn-md" to="/contact">
                          <span>Contact Me</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="webcv-a webcv-pagination">
                    <a
                      role="button"
                      onClick={() =>
                        getAnotherProject(
                          projectActionType.Previous,
                          Number(id)
                        )
                      }
                      className="webcv-link webcv-color-link webcv-w-chevron"
                    >
                      <span className="pr-1">
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </span>
                      <span className="pl-1">Previous project</span>
                    </a>
                    <div className="webcv-pagination-center webcv-m-hidden">
                      <Link className="webcv-link" to="/projects">
                        All projects
                      </Link>
                    </div>
                    <a
                      role="button"
                      onClick={() =>
                        getAnotherProject(projectActionType.Next, Number(id))
                      }
                      className="webcv-link webcv-color-link webcv-w-chevron"
                    >
                      <span className="pr-1">Next project</span>
                      <span className="pl-1">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbar>
        </div>
      ) : (
        <NotFoundPage />
      )}
    </>
  );
};

export default ProjectDetailsPage;
