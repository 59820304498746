import React from "react";
import { educationTimeline } from "shared/data/education-timeline.data";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EducationTimeline = () => {
  return (
    <>
      {" "}
      <div className="webcv-section-title">
        <div className="webcv-title-frame">
          <h4>Education</h4>
        </div>
      </div>
      {/*Education Timeline*/}
      <div className="webcv-timeline webcv-gallery">
        {educationTimeline.map((entry) => (
          <div key={entry.id} className="webcv-timeline-item">
            <div className="webcv-timeline-mark-light"></div>
            <div className="webcv-timeline-mark"></div>

            <div className="webcv-a webcv-timeline-content">
              <div className="webcv-card-header">
                <div className="webcv-left-side">
                  <h5>{entry.schoolName}</h5>
                  <div className="webcv-el-suptitle">{entry.schoolCountry}</div>
                  <div className="webcv-timeline-designation mb-15 mt-2">
                    {entry.schoolMarks}
                  </div>
                </div>
                <div className="webcv-right-side">
                  <span className="webcv-date">{entry.schoolYear}</span>
                </div>
              </div>
              <ul className="webcv-timeline-list-info">
                {entry.schoolHighlights.map((achievement) => (
                  <li key={achievement.id} className="text-justify">
                    <FontAwesomeIcon className="check-icon" icon={faCircle} />
                    <span>{achievement.highlightText}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default EducationTimeline;
