import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import AnimatedProgressProvider from "shared/providers/animated-progress-provider";
import { easeQuadInOut } from "d3-ease";

const Loading = () => {
  return (
    <div className="webcv-preloader">
      <div className="webcv-preloader-content">
        <h4>Loading</h4>
        <div id="preloader"></div>
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={100}
          duration={1.2}
          easingFunction={easeQuadInOut}
        >
          {(value) => {
            const roundedValue = Math.round(value);
            return (
              <ProgressBar
                now={roundedValue}
                className="webcv-preloader-load"
              />
            );
          }}
        </AnimatedProgressProvider>
      </div>
    </div>
  );
};

export default Loading;
