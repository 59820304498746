export const experienceTimeline = [
  {
    id: 1,
    orgName: "Paypa Plane",
    orgCountry: "Brisbane, Australia",
    orgDesignation: "Frontend Engineer",
    orgYear: "Aug 2022 - Present",
    orgHighlights: [
      {
        id: 1.1,
        highlightText:
          "Created various functionalities for a smart payment agreements platform that oversees automated recurring payments across diverse channels",
      },
      {
        id: 1.2,
        highlightText:
          "Developed interfaces to incorporate payment solutions such as PayTo and built white label features for banks and their business customers",
      },
    ],
  },
  {
    id: 2,
    orgName: "Swissport",
    orgCountry: "Brisbane, Australia",
    orgDesignation: "Developer",
    orgYear: "April 2021 - June 2022",
    orgHighlights: [
      {
        id: 2.1,
        highlightText:
          "Worked on building a global ERP solution to replace a legacy operations management system and created a unified digital platform for Asia-Pacific region",
      },
    ],
  },
  {
    id: 3,
    orgName: "BuildingPoint",
    orgDesignation: "Developer",
    orgCountry: "Brisbane, Australia",
    orgYear: "Dec 2020 - April 2021",
    orgHighlights: [
      {
        id: 3.1,
        highlightText:
          "Built a responsive UI interface for a legacy civil engineering application migrated to cloud",
      },
    ],
  },
  {
    id: 4,
    orgName: "Fidelity International",
    orgCountry: "Gurugram, India",
    orgDesignation: "Programmer",
    orgYear: "July 2016 - Jan 2019",
    orgHighlights: [
      {
        id: 4.1,
        highlightText:
          "Developed a hybrid mobile application for IOS and Android for a target audience of 500k users within the first year of its release to facilitate wealth and pensions management for members in the UK region",
      },
      {
        id: 4.2,
        highlightText:
          "Implemented UI journeys for authentication mechanisms like Face ID, Touch ID and Fingerprint ID Biometrics as part of mobile application development using Ionic and Angular",
      },
      {
        id: 4.3,
        highlightText:
          "Served as Web Developer and contributed towards design, development and enhancement of 10+ responsive web applications",
      },
      {
        id: 4.4,
        highlightText:
          "Managed the department's intranet for internal surveys, quizzes and feedback forums using platforms like SharePoint",
      },
    ],
  },
];
