import React from "react";
import SwiperWrapper from "shared/components/swiper-configuration/swiper-configuration";
import defaultImage from "assets/img/useravatar.png";

const Recommendations = (props) => {
  function addDefaultSrc(ev) {
    ev.target.src = defaultImage;
  }

  return (
    <div className="container-fluid pb-3">
      <div className="row">
        {" "}
        <div className="col-lg-12">
          {" "}
          <div className="webcv-section-title">
            <div className="webcv-title-frame">
              <h4>Recommendations</h4>
            </div>
          </div>
        </div>
        {/*  Recommendations */}
        {props.data.length > 1 ? (
          <SwiperWrapper
            data={props.data}
            sliderClass="swiper-container webcv-testimonial-slider"
          />
        ) : (
          props.data.map((item) => (
            <div className="col-lg-12 mb-4" key={item.id}>
              <div className="webcv-a webcv-testimonial h-100">
                {" "}
                <div className="testimonial-body">
                  {" "}
                  <div className="row">
                    <div className="col-md-1 mb-4 mb-md-0">
                      {" "}
                      <img
                        width="60"
                        src={
                          require(`assets/img/linkedIn/avatar/${item.pictureUrl}`)
                            .default
                        }
                        loading="lazy"
                        height="60"
                        alt="User"
                        className="border-radius-50"
                        onError={addDefaultSrc}
                      />
                    </div>
                    <div className="col-md-11">
                      <h5>{item.by}</h5>{" "}
                      <div className="webcv-el-suptitle mb-15">
                        {item.designation}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="mb-15 text-justify mt-3">{item.text}</div>
                  <div className="webcv-testimonial-footer mt-auto">
                    <div className="webcv-left-side">
                      <div className="webcv-star-rate">
                        <span>{item.info}</span>
                      </div>
                    </div>
                    <div className="webcv-right-side">
                      <span className="post-message">
                        Originally posted on LinkedIn
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Recommendations;
