import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { routePaths } from "shared/data/app-routes.data";
import "assets/fonts/Righteous/Righteous-Regular.ttf";
import "assets/template/scss/style.scss";
import "App.css";
import {
  RightNavigation,
  LeftProfilePanel,
  ParticleJsConfiguration,
  NotFoundPage,
  ScrollToTop,
} from "shared/components/index";
import LandingPage from "components/landing-page/landing-page";
import HistoryPage from "components/history-page/history-page";
import ProjectsPage from "components/projects-page/projects-page";
import ProjectDetailsPage from "components/project-details-page/project-details-page";
import ArtPage from "components/art-page/art-page";
import LetteringPage from "components/lettering-page/lettering-page";
import PhotographyPage from "components/photography-page/photography-page";
import WriteUpsPage from "components/writing-page/writing-page";
import WriteUpDetailsPage from "components/writing-details-page/writing-details-page";
import ContactPage from "components/contact-page/contact-page";
import Particles from "react-particles-js";
function App() {
  let location = useLocation();
  const [appLoading, setAppLoading] = useState(true);

  //left frame is the profile frame
  const [isLeftFrameOpen, setisLeftFrameOpen] = useState(false);
  //right navigation menu state
  const [isNavBarOpen, setisNavBarOpen] = useState(false);

  //hide profile menu
  const [hideProfileMenu, setHideProfileMenu] = useState(false);

  function setDocHeight() {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    );
  }

  window.addEventListener("resize", function () {
    setDocHeight();
  });
  window.addEventListener("orientationchange", function () {
    setDocHeight();
  });

  useEffect(() => {
    setAppLoading(false);
    setHideProfileMenu(false);
    setDocHeight();
  }, [appLoading, location]);
  function toggleInfobar() {
    //open-close left profile frame
    setisLeftFrameOpen(!isLeftFrameOpen);
  }

  function toggleNavbar() {
    //open-close right navbar menu
    setisNavBarOpen(!isNavBarOpen);
  }

  function closeAllPanels() {
    if (isLeftFrameOpen) {
      setisLeftFrameOpen(!isLeftFrameOpen);
    }
    if (isNavBarOpen) {
      setisNavBarOpen(!isNavBarOpen);
    }
  }

  function hideProfile() {
    setHideProfileMenu(!hideProfileMenu);
  }

  return (
    <div className="webcv-app">
      <div className="webcv-mobile-top-bar" onClick={closeAllPanels}></div>
      <div className="webcv-app-wrapper">
        <div className="webcv-app-container">
          {/* Left Profile Menu*/}
          <LeftProfilePanel
            appLoading={appLoading}
            isLeftFrameOpen={isLeftFrameOpen}
            toggleInfobar={toggleInfobar}
            isDisabled={isNavBarOpen}
            hideProfileMenu={hideProfileMenu}
          ></LeftProfilePanel>
          <Particles params={ParticleJsConfiguration} />
          <ScrollToTop />
          <Switch>
            {/* Default Landing Page */}
            <Route
              path="/"
              exact
              render={(props) => (
                <LandingPage
                  isDisabled={isLeftFrameOpen || isNavBarOpen}
                  closeAllPanels={closeAllPanels}
                  {...props}
                />
              )}
            />
            {/* Home Page */}
            <Route
              path={routePaths.home.path}
              exact
              render={(props) => (
                <LandingPage
                  isDisabled={isLeftFrameOpen || isNavBarOpen}
                  closeAllPanels={closeAllPanels}
                  {...props}
                />
              )}
            ></Route>
            {/* History Page */}
            <Route
              path={routePaths.history.path}
              exact
              render={(props) => (
                <HistoryPage
                  isDisabled={isLeftFrameOpen || isNavBarOpen}
                  closeAllPanels={closeAllPanels}
                  {...props}
                />
              )}
            ></Route>
            {/* Projects Page */}
            <Route
              path={routePaths.projects.path}
              exact
              render={(props) => (
                <ProjectsPage
                  isDisabled={isLeftFrameOpen || isNavBarOpen}
                  closeAllPanels={closeAllPanels}
                  {...props}
                />
              )}
            ></Route>
            {/* Project Details Page */}
            <Route
              path={routePaths.projectDetails.path}
              exact
              render={(props) => (
                <ProjectDetailsPage
                  isDisabled={isLeftFrameOpen || isNavBarOpen}
                  closeAllPanels={closeAllPanels}
                  hideProfile={hideProfile}
                  {...props}
                />
              )}
            ></Route>
            {/* Art Stuff Page */}
            <Route
              path={routePaths.interests.path.art.path}
              exact
              render={(props) => (
                <ArtPage
                  isDisabled={isLeftFrameOpen || isNavBarOpen}
                  closeAllPanels={closeAllPanels}
                  hideProfile={hideProfile}
                  {...props}
                />
              )}
            ></Route>
            {/* Handlettering Page */}
            <Route
              path={routePaths.interests.path.handlettering.path}
              exact
              render={(props) => (
                <LetteringPage
                  isDisabled={isLeftFrameOpen || isNavBarOpen}
                  closeAllPanels={closeAllPanels}
                  hideProfile={hideProfile}
                  {...props}
                />
              )}
            ></Route>
            {/* Photography Page */}
            <Route
              path={routePaths.interests.path.photography.path}
              exact
              render={(props) => (
                <PhotographyPage
                  isDisabled={isLeftFrameOpen || isNavBarOpen}
                  closeAllPanels={closeAllPanels}
                  hideProfile={hideProfile}
                  {...props}
                />
              )}
            ></Route>
            {/* Write Ups Page */}
            <Route
              path={routePaths.interests.path.writeups.path}
              exact
              render={(props) => (
                <WriteUpsPage
                  isDisabled={isLeftFrameOpen || isNavBarOpen}
                  closeAllPanels={closeAllPanels}
                  hideProfile={hideProfile}
                  {...props}
                />
              )}
            ></Route>
            {/* Write Up Details Page */}
            <Route
              path={routePaths.interests.path.writeupDetails.path}
              exact
              render={(props) => (
                <WriteUpDetailsPage
                  isDisabled={isLeftFrameOpen || isNavBarOpen}
                  closeAllPanels={closeAllPanels}
                  hideProfile={hideProfile}
                  {...props}
                />
              )}
            ></Route>
            {/* Contact Page */}
            <Route
              path={routePaths.contact.path}
              exact
              render={(props) => (
                <ContactPage
                  isDisabled={isLeftFrameOpen || isNavBarOpen}
                  closeAllPanels={closeAllPanels}
                  hideProfile={hideProfile}
                  {...props}
                />
              )}
            ></Route>
            {/* Not Found Page */}
            <Route render={() => <NotFoundPage />}></Route>
          </Switch>
          <RightNavigation
            isNavBarOpen={isNavBarOpen}
            toggleNavbar={toggleNavbar}
            isDisabled={isLeftFrameOpen}
          ></RightNavigation>
        </div>
      </div>
    </div>
  );
}

export default App;

//State Hooks Example for future reference
// eslint-disable-next-line no-lone-blocks
{
  /*
 //hamburger toggler is used for mobile layouts
 const [hamburgerToggler, setHamburgerToggler] = useState(true);
 //lightbox open /close state detector
 const [isLightboxClosed, setIsLightBoxClosed] = useState(true);
 const notInitialRender = useRef(false);


  //notify that light box state is open
  setIsLightBoxClosed(false);



  useEffect(() => {
    //ignore on page load; perform after
    if (notInitialRender.current) {
      //if lightBox is closed; reopen left frame
      if (isLightboxClosed) {
        setHamburgerToggler(true);
        setisLeftFrameOpen(true);
      } else {
        //if lightBox is being opened; hide hamburger and left frame
        setisLeftFrameOpen(false);
        setHamburgerToggler(false);
      }
    } else {
      //set prop to true after first render
      notInitialRender.current = true;
    }
  }, [isLightboxClosed]);
  */
}
