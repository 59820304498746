import React from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import { CareerHighlights, HeaderImage, Awards } from "../landing-page";
import { Recommendations, ProgressNumbers } from "shared/components/index";
import { recommendations } from "shared/data/landing-page.data";
const LandingPage = (props) => {
  return (
    <div className={`webcv-content ${props.isDisabled ? "webcv-active" : ""}`}>
      <div className="webcv-curtain" onClick={props.closeAllPanels}></div>
      <div className="webcv-top-bg">
        <div className="webcv-top-bg-overlay"></div>
      </div>
      <Scrollbar
        damping={0.2}
        renderByPixels={true}
        continuousScrolling={true}
        className="webcv-scroll-frame"
      >
        <HeaderImage />
        <ProgressNumbers />
        <CareerHighlights />
        <Awards />
        <div className="pb-5">
          <Recommendations data={recommendations} />
        </div>
      </Scrollbar>
    </div>
  );
};

export default LandingPage;
