import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import defaultImage from "assets/img/useravatar.png";

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay]);
const SwiperWrapper = (props) => {
  const params = {
    loop: true,
    resizeObserver: true,
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 1400,
    autoplay: { delay: 5000, pauseOnMouseEnter: true },
    mousewheel: true,
    pagination: {
      dynamicBullets: true,
      clickable: true,
    },
    breakpoints: {
      2400: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 2,
      },
    },
  };

  function addDefaultSrc(ev) {
    ev.target.src = defaultImage;
  }

  return (
    <>
      <div className="col-lg-12">
        <Swiper {...params} className={`swiper-container ${props.sliderClass}`}>
          {props.data.reverse().map((item) => (
            <SwiperSlide key={item.id}>
              <div className="webcv-a webcv-testimonial h-100">
                {" "}
                <div className="testimonial-body">
                  {" "}
                  <div className="row">
                    <div className="col-md-2 mb-4 mb-md-0">
                      {" "}
                      <img
                        width="60"
                        src={
                          require(`assets/img/linkedIn/avatar/${item.pictureUrl}`)
                            .default
                        }
                        loading="lazy"
                        height="60"
                        alt="User"
                        className="border-radius-50"
                        onError={addDefaultSrc}
                      />
                    </div>
                    <div className="col-md-10">
                      <h5>{item.by}</h5>{" "}
                      <div className="webcv-el-suptitle mb-15">
                        {item.designation}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="mb-15 text-justify mt-3">{item.text}</div>
                  <div className="webcv-testimonial-footer mt-auto">
                    <div className="webcv-left-side">
                      <div className="webcv-star-rate">
                        <span>{item.info}</span>
                      </div>
                    </div>
                    <div className="webcv-right-side">
                      <span className="post-message">
                        Originally posted on LinkedIn
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};
export default SwiperWrapper;
