/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import { projectTypes } from "shared/data/projects-page.data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import FlipMove from "react-flip-move";
import { getProjects } from "shared/providers/projects-provider";
import { useHistory } from "react-router-dom";

const ProjectsPage = (props) => {
  const history = useHistory();
  const [allProjectsData, setAllProjectsData] = useState([]);
  const [filteredProjectsData, setfilteredProjectsData] = useState([]);
  const [activeTypeID, setActiveTypeID] = useState(0);

  function seeDetails(url) {
    return history.push(url);
  }
  useEffect(() => {
    const projectsData = getProjects();
    setAllProjectsData(projectsData);
    setfilteredProjectsData(allProjectsData);
  }, [allProjectsData]);
  function filterProject(projects) {
    setActiveTypeID(projects.id);
    let filteredData;
    if (projects.type === projectTypes.all.type) {
      filteredData = allProjectsData;
    } else {
      filteredData = allProjectsData.filter((project) => {
        return project.projectType.type === projects.type;
      });
    }

    setfilteredProjectsData(filteredData);
  }

  return (
    <>
      <div
        className={`webcv-content ${props.isDisabled ? "webcv-active" : ""}`}
      >
        <div className="webcv-curtain" onClick={props.closeAllPanels}></div>
        <div className="webcv-top-bg">
          <div className="webcv-top-bg-overlay"></div>
        </div>
        <Scrollbar
          damping={0.2}
          renderByPixels={true}
          continuousScrolling={true}
          className="webcv-scroll-frame"
        >
          <div className="container-fluid webcv-scroll-frame">
            {" "}
            <div className="row p-30-0">
              <div className="col-lg-12">
                {" "}
                <div className="webcv-filter mb-30">
                  {Object.values(projectTypes).map((projectType) => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      role="button"
                      key={projectType.id}
                      className={`webcv-link ${
                        activeTypeID === projectType.id ? "webcv-current" : ""
                      }`}
                      onClick={() => filterProject(projectType)}
                    >
                      {projectType.type}
                    </a>
                  ))}
                </div>
              </div>
              <div className="webcv-grid webcv-grid-3-col webcv-gallery">
                <FlipMove>
                  {filteredProjectsData.map((projects) => (
                    <div key={projects.id} className="webcv-grid-item">
                      <div className="d-contents">
                        <span
                          className="
                            webcv-a webcv-portfolio-item-frame webcv-square"
                        >
                          <img
                            src={
                              require(`assets/img/projects/thumbnail/${projects.projectImagePath}`)
                                .default
                            }
                            alt="ProjectImage"
                          />
                        </span>
                        <div className="webcv-item-description">
                          <h5 className="mb-15">{projects.projectName}</h5>
                          <div className="mb-15">
                            {projects.projectDescription}
                          </div>
                          {projects.hasDetails ? (
                            <a
                              role="button"
                              onClick={() =>
                                seeDetails(`/projects/${projects.projectID}`)
                              }
                              className="webcv-link webcv-color-link webcv-w-chevron"
                            >
                              Read more
                              <span className="pl-1">
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  className="webcv-w-chevron-tr"
                                />
                              </span>
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </FlipMove>
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>
    </>
  );
};

export default ProjectsPage;
