/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import {
  faChevronLeft,
  faChevronRight,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotFoundPage } from "shared/components/index";
import { Link, useHistory } from "react-router-dom";
import {
  getWriteUpDetails,
  getTotalWriteUps,
} from "shared/providers/writeups-provider";
import staticImage from "assets/img/vector/strategy.png";

const WriteUpDetailsPage = (props) => {
  const writeUpActionType = {
    Previous: "previous",
    Next: "next",
  };
  const history = useHistory();

  const { id } = props.match.params;
  const selectedWriteUpData = getWriteUpDetails(Number(id));
  const totalProjects = getTotalWriteUps();
  const writeUpData = selectedWriteUpData[0];

  function getAnotherWriteUp(actionType, currentWriteUpID) {
    let newProjectID;
    if (actionType === writeUpActionType.Previous) {
      newProjectID =
        currentWriteUpID - 1 < 1 ? totalProjects : currentWriteUpID - 1;
    } else if (actionType === writeUpActionType.Next) {
      newProjectID =
        currentWriteUpID + 1 <= totalProjects ? currentWriteUpID + 1 : 1;
    }
    history.push(`/writeups/${newProjectID}`);
  }

  return (
    <>
      {writeUpData ? (
        <div
          className={`webcv-content ${props.isDisabled ? "webcv-active" : ""}`}
        >
          <div className="webcv-curtain" onClick={props.closeAllPanels}></div>
          <div className="webcv-top-bg">
            <div className="webcv-top-bg-overlay"></div>
          </div>
          <Scrollbar
            damping={0.2}
            renderByPixels={true}
            continuousScrolling={true}
            className="webcv-scroll-frame"
          >
            {/* Write Up Details Header */}
            <div className="container-fluid">
              <div className="row p-30-0">
                <div className="col-lg-12">
                  {" "}
                  <div className="webcv-section-title">
                    {/* Write up Category */}
                    <div className="webcv-title-frame">
                      <h4>Category : {writeUpData.writeUpDetails.category}</h4>
                    </div>
                    {/* Write up Tags */}
                    <div className="webcv-right-frame">
                      <div className="webcv-project-category">
                        {writeUpData.writeUpDetails.tags &&
                          writeUpData.writeUpDetails.tags.map((tag, i, arr) => (
                            <span key={i}>
                              {tag}
                              {i !== arr.length - 1 ? ", " : ""}
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Write Yp Details Description */}
            <div className="container-fluid">
              <div className="row">
                {/* Description */}
                <div className="col-lg-12">
                  <div className="webcv-a webcv-card">
                    <blockquote>
                      <FontAwesomeIcon
                        icon={faQuoteLeft}
                        className="blockquote-icon before"
                      />
                      {/* Title */}
                      <i>{writeUpData.writeUpDetails.title}</i>
                      <FontAwesomeIcon
                        icon={faQuoteRight}
                        className="blockquote-icon after"
                      />
                    </blockquote>
                    <div className="row">
                      <div className="col-xl-10">
                        <p
                          className="text-justify white-color font-size-14"
                          dangerouslySetInnerHTML={{
                            __html: writeUpData.writeUpDetails.content,
                          }}
                        ></p>
                      </div>
                      <div className="col-xl-2 self-align-right">
                        <img
                          src={staticImage}
                          className="w-70"
                          alt="ProjectImage"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="webcv-a webcv-pagination">
                    <a
                      role="button"
                      onClick={() =>
                        getAnotherWriteUp(
                          writeUpActionType.Previous,
                          Number(id)
                        )
                      }
                      className="webcv-link webcv-color-link webcv-w-chevron"
                    >
                      <span className="pr-1">
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </span>
                      <span className="pl-1">Previous writeup</span>
                    </a>
                    <div className="webcv-pagination-center webcv-m-hidden">
                      <Link className="webcv-link" to="/writeups">
                        All Write Ups
                      </Link>
                    </div>
                    <a
                      role="button"
                      onClick={() =>
                        getAnotherWriteUp(writeUpActionType.Next, Number(id))
                      }
                      className="webcv-link webcv-color-link webcv-w-chevron"
                    >
                      <span className="pr-1">Next writeup</span>
                      <span className="pl-1">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbar>
        </div>
      ) : (
        <NotFoundPage />
      )}
    </>
  );
};

export default WriteUpDetailsPage;
