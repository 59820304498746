import React from "react";
import AnimatedProgressProvider from "shared/providers/animated-progress-provider";
import { easeQuadInOut } from "d3-ease";
import { progressNumbers } from "shared/data/landing-page.data";
const ProgressNumbers = () => {
  return (
    <div className="container-fluid">
      {" "}
      <div className="row p-30-0">
        {progressNumbers.map((numberItem, index) => (
          <div key={numberItem.id} className="col-md-3 col-6">
            <div className="webcv-counter-frame">
              {" "}
              <div className="webcv-counter-box text-lg-center">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={numberItem.progressEnd}
                  duration={1.4}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                      <div>
                        {" "}
                        <span className="webcv-counter">{roundedValue}</span>
                        {index + 1 !== progressNumbers.length ? (
                          <span className="webcv-counter-plus">+</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
              <h6>{numberItem.progressText}</h6>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressNumbers;
