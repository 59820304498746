import React from "react";
import {
  careerHighlights,
  careerHighlightsType,
} from "shared/data/landing-page.data";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CareerHighlights = () => {
  return (
    <div className="container-fluid">
      {" "}
      <div className="row">
        <div className="col-lg-12">
          {" "}
          <div className="webcv-section-title">
            {" "}
            <div className="webcv-title-frame">
              {" "}
              <h4>Career Highlights</h4>
            </div>
          </div>
        </div>
        {Object.entries(careerHighlightsType).map(([key, value]) => {
          return (
            <div key={key} className="col-lg-4 d-grid-column">
              <div className="webcv-a webcv-price">
                <div className="webcv-price-body">
                  <h5 className="main-color mb-30">{value.displayName}</h5>
                  <ul className="webcv-price-list text-justify">
                    {careerHighlights
                      .filter((item) => {
                        return item.type === value.type;
                      })
                      .map((achievement) => (
                        <li key={achievement.id}>
                          <FontAwesomeIcon
                            className="check-icon"
                            icon={faCheck}
                          />
                          <span>{achievement.highlightText}</span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CareerHighlights;
