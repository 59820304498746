export const photographyListData = [
  { id: 46, imagePath: `46.jpg` },
  { id: 47, imagePath: `47.jpg` },
  { id: 48, imagePath: `48.jpg` },
  { id: 49, imagePath: `49.jpg` },
  { id: 50, imagePath: `50.jpg` },
  { id: 51, imagePath: `51.jpg` },
  { id: 52, imagePath: `52.jpg` },
  { id: 53, imagePath: `53.jpg` },
  { id: 54, imagePath: `54.jpg` },
  { id: 1, imagePath: "1.jpg" },
  { id: 2, imagePath: `2.jpg` },
  { id: 3, imagePath: `3.jpg` },
  { id: 4, imagePath: `4.jpg` },
  { id: 5, imagePath: `5.jpg` },
  { id: 7, imagePath: `7.jpg` },
  { id: 8, imagePath: `8.jpg` },
  { id: 9, imagePath: `9.jpg` },
  { id: 10, imagePath: "10.jpg" },
  { id: 11, imagePath: `11.jpg` },
  { id: 12, imagePath: `12.jpg` },
  { id: 13, imagePath: `13.jpg` },
  { id: 14, imagePath: `14.jpg` },
  { id: 15, imagePath: `15.jpg` },
  { id: 16, imagePath: `16.jpg` },
  { id: 17, imagePath: `17.jpg` },
  { id: 18, imagePath: "18.jpg" },
  { id: 19, imagePath: `19.jpg` },
  { id: 20, imagePath: `20.jpg` },
  { id: 21, imagePath: `21.jpg` },
  { id: 24, imagePath: `24.jpg` },
  { id: 25, imagePath: `25.jpg` },
  { id: 26, imagePath: `26.jpg` },
  { id: 27, imagePath: `27.jpg` },
  { id: 28, imagePath: `28.jpg` },
  { id: 29, imagePath: `29.jpg` },
  { id: 30, imagePath: `30.jpg` },
  { id: 31, imagePath: `31.jpg` },
  { id: 32, imagePath: `32.jpg` },
  { id: 33, imagePath: `33.jpg` },
  { id: 34, imagePath: `34.jpg` },
  { id: 35, imagePath: `35.jpg` },
  { id: 36, imagePath: `36.jpg` },
  { id: 37, imagePath: `37.jpg` },
  { id: 38, imagePath: `38.jpg` },
  { id: 39, imagePath: `39.jpg` },
  { id: 40, imagePath: `40.jpg` },
  { id: 41, imagePath: `41.jpg` },
  { id: 42, imagePath: `42.jpg` },
  { id: 43, imagePath: `43.jpg` },
  { id: 44, imagePath: `44.jpg` },
  { id: 45, imagePath: `45.jpg` },
];
