import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AnimatedProgressProvider from "shared/providers/animated-progress-provider";
import { easeQuadInOut } from "d3-ease";
import { Scrollbar } from "smooth-scrollbar-react";
import {
  languageSkills,
  otherSkills,
  knowledgeList,
  socialMediaPlatformsList,
  socialMediaLinks,
} from "shared/data/profile-details-skills.data.js";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import React, { useState } from "react";

const ProfileDetails = (props) => {
  const { basicInfo } = props;
  const socialMediaIconSet = [
    { platform: socialMediaPlatformsList.LinkedIn, icon: faLinkedin },
    { platform: socialMediaPlatformsList.Youtube, icon: faYoutube },
    { platform: socialMediaPlatformsList.Facebook, icon: faFacebook },
    { platform: socialMediaPlatformsList.Instagram, icon: faInstagram },
  ];

  const [fetching, setFetching] = useState(false);

  const download = (url, name) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setFetching(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        setFetching(false);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.className = "d-none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch(() =>
        console.error("Could not download Resume. Please Try again later.")
      );
  };
  return (
    <div>
      <Scrollbar
        damping={0.2}
        renderByPixels={true}
        continuousScrolling={true}
        className="webcv-scroll-frame"
      >
        {/*Basic Detials */}
        {/*info */}
        <div className="webcv-table p-15-15">
          <ul>
            <li>
              <h6>Email:</h6>
              <span className="mt-top-fix">{basicInfo.email}</span>
            </li>
            <li>
              <h6>City:</h6>
              <span className="mt-top-fix">{basicInfo.city}</span>
            </li>
          </ul>
        </div>

        {/* divider */}
        <div className="webcv-ls-divider"></div>

        {/* major skills circle bar*/}
        <div className="webcv-lang-skills p-30-15">
          {languageSkills.map((skill) => (
            <div key={skill.id} className="webcv-lang-skills-item">
              <AnimatedProgressProvider
                valueStart={0}
                valueEnd={skill.progressLevel}
                duration={1.4}
                easingFunction={easeQuadInOut}
              >
                {(value) => {
                  const roundedValue = Math.round(value);
                  return (
                    <CircularProgressbar
                      value={roundedValue}
                      text={`${roundedValue}%`}
                      className="webcv-cirkle-progress"
                      styles={buildStyles({ pathTransition: "none" })}
                    />
                  );
                }}
              </AnimatedProgressProvider>
              <h6>{skill.skillName}</h6>
            </div>
          ))}
        </div>

        {/* divider */}
        <div className="webcv-ls-divider"></div>

        {/* other skills line bar */}
        <div className="webcv-hard-skills p-30-15">
          {otherSkills.map((skill) => (
            <div key={skill.id}>
              <AnimatedProgressProvider
                valueStart={0}
                valueEnd={skill.progressLevel}
                duration={1.2}
                easingFunction={easeQuadInOut}
              >
                {(value) => {
                  const roundedValue = Math.round(value);
                  return (
                    <div className="webcv-hard-skills-item">
                      <div className="webcv-skill-heading pb-1">
                        <h6>{skill.skillName}</h6>
                        <span>{roundedValue}%</span>
                      </div>
                      <ProgressBar
                        now={roundedValue}
                        className="webcv-line-progress"
                      />
                    </div>
                  );
                }}
              </AnimatedProgressProvider>
            </div>
          ))}
        </div>

        {/* divider */}
        <div className="webcv-ls-divider"></div>

        {/* Knowledge  List */}
        <ul className="webcv-knowledge-list p-15-0">
          {knowledgeList.map((item) => (
            <li key={item.id}>
              {" "}
              <FontAwesomeIcon className="check-icon-style" icon={faCheck} />
              <h6 className="d-inline">{item.skillTitle} : </h6>{" "}
              {item.skillName}
            </li>
          ))}
        </ul>

        {/* divider */}
        <div className="webcv-ls-divider"></div>

        {/* download CV */}
        <div className="webcv-links-frame p-15-15 pb-5">
          <button
            disabled={fetching}
            onClick={() => download("cv/PalashCV.pdf", "palashCv.pdf")}
            className="webcv-link clear-back"
          >
            Download Resume <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      </Scrollbar>
      {/* Social Media Links */}
      <div className="webcv-ls-social">
        {socialMediaLinks.map((item) => (
          <a
            key={item.id}
            rel="noopener noreferrer"
            href={item.profileLink}
            target="_blank"
          >
            {socialMediaIconSet
              .filter((arrItem) => arrItem.platform === item.platform)
              .map((filterItem) => (
                <FontAwesomeIcon key={item.id} icon={filterItem.icon} />
              ))}
          </a>
        ))}
      </div>
    </div>
  );
};

export default ProfileDetails;
