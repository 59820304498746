export const imageHeader = {
  titleText: "Hello World!",
  animatedTextCaption: "I create",
  animatedTextSubstrings: [
    "UI Flows",
    "Digital Designs",
    "Hand Made Crafts",
  ],
  redirectButtonText: "See Projects",
};

export const progressNumbers = [
  { id: 1, progressEnd: 20, progressText: "Completed Projects" },
  { id: 2, progressEnd: 7, progressText: "Years of Work Experience" },
  { id: 3, progressEnd: 10, progressText: "Awards Recieved" },
  { id: 4, progressEnd: 2, progressText: "Research Paper Published" },
];

export const careerHighlightsType = {
  Achievements: { displayName: "Achievements", type: "achievements" },
  LeadershipSkills: {
    displayName: "Leadership Skills",
    type: "leadershipSkills",
  },
  AnalyticalSkills: {
    displayName: "Analytical Skills",
    type: "analyticalSkills",
  },
};

export const careerHighlights = [
  {
    id: 6,
    highlightText:
      "Freelance Developer - developed websites for organisations like Brisbane Capital, Swades Foods etc. to set up their digital presence",
    type: careerHighlightsType.Achievements.type,
  },
  {
    id: 1,
    highlightText:
      "Letter of Recommendation by Ex-Head of Google India Operations for identifying 200+ bugs across 2 web products",
    type: careerHighlightsType.Achievements.type,
  },

  {
    id: 4,
    highlightText:
      "Organised 10+ technical, cultural and co-curricular workshops for students from multiple universities",
    type: careerHighlightsType.Achievements.type,
  },

  {
    id: 7,
    highlightText: "Co-Founder - RCubers (Web Development start-up, 2014-2016)",
    type: careerHighlightsType.LeadershipSkills.type,
  },
  {
    id: 8,
    highlightText:
      "Recognized as Google Student Ambassador (2014-2016) and acted as a liasion between Google and The Northcap University, Haryana (India)",
    type: careerHighlightsType.LeadershipSkills.type,
  },
  {
    id: 10,
    highlightText:
      "Tutored 92 students for 6 weeks for a Digital Marketing Course affiliated by Google",
    type: careerHighlightsType.LeadershipSkills.type,
  },
  {
    id: 11,
    highlightText:
      "Developed 'Shikshak' - A tutor system for the blind with research paper published in the International Journal of Computer Science and Mobile Computing (IF - 6.017)",
    type: careerHighlightsType.AnalyticalSkills.type,
  },
  {
    id: 12,
    highlightText:
      "Published a research paper titled 'Using Gamification to Promote Student Engagement in STEM Project-Based Learning'",
    type: careerHighlightsType.AnalyticalSkills.type,
  },
];

export const awards = [
  {
    id: 1,
    awardName: "Paypa Plane's Award",
    awardDescription:
      "For consistently striving for excellence and always encouraging to improve",
  },
  {
    id: 2,
    awardName: "QUT Dean's List Awardee",
    awardDescription:
      "Recognised for excellent academic performance in all semesters",
  },
  {
    id: 3,
    awardName: "Governing Council Award",
    awardDescription:
      "Awarded by Fidelity council for commitment and client focus",
  },
  {
    id: 4,
    awardName: "Fidelity On The Spot Award",
    awardDescription: "For demonstrating company's core behaviours and morals",
  },
  {
    id: 5,
    awardName: "Fidelity Director's Award",
    awardDescription:
      "For outstanding contribution and challenging the status quo",
  },
  {
    id: 6,
    awardName: "Skyfi Labs Best Project Award (Nationwide Winner)",
    awardDescription:
      "Won Best Project Award amongst 500 projects from different departments",
  },
  {
    id: 7,
    awardName: "Happening Haryana Start Up Weekend - Winner",
    awardDescription:
      "Won on spot funding for an app idea amongst 300 students",
  },
  {
    id: 8,
    awardName: "NASSCOM Start Up with Maximum Potential Award",
    awardDescription:
      "Recognised by NASSCOM for a mobile application start-up idea pitch",
  },
  {
    id: 9,
    awardName: "Best Project for a Social Cause",
    awardDescription: "Won 'Best Social Cause Project' amongst 250 projects",
  },
];

export const recommendations = [
  {
    id: 29,
    pictureUrl: "mike.jpeg",
    by: "Mike Lennox",
    designation: "Project Manager, Swissport",
    text: `I worked with Palash on a number of projects whilst he was employed at Swissport. Palash produced first class work that was always aligned with the project scope and required functionality. He attended scoping sessions and often provided input into the solution, making suggestions as to how development can be changed to deliver the project in a shorter time frame or with better functionality. He is a professional, well presented and attentive individual and as a Project Manager I would not hesitate to recommend Palash to other employees.`,
    info: "June 8, 2022",
  },
  {
    id: 28,
    pictureUrl: "renato.jpeg",
    by: "Renato Frias",
    designation: "Software Developer, Swissport",
    text: `I had the pleasure to work with Palash for a short but memorable time. He is not only a very capable developer but also one of the best mentors I've ever had. He got the skills to combine his outstanding knowledge with great lecturing techniques to guide you and support.`,
    info: "June 3, 2022",
  },
  {
    id: 27,
    pictureUrl: "damon.jpeg",
    by: "Damon McManus",
    designation: "Senior Sytems Engineer, Swissport",
    text: `From the start of his time working at Swissport Palash challenged himself to step outside his comfort zone in order to improve our development processes. He worked continually to make our sprints more streamlined resulting in us being able to adapt quickly to the changing needs of our stakeholders. And Palash brought in thorough review processes and pushed for better execution of the Agile Project Management principals. In addition to this, Palash is a highly competent developer who has done great User Interface work on the front end of our application. I am confident that where ever he goes next he will bring the same level of commitment to his work.`,
    info: "June 3, 2022",
  },
  {
    id: 26,
    pictureUrl: "edgar.jpeg",
    by: "Edgar Emiro Luzardo",
    designation: "Front-end Team Lead, National Disability Insurance Agency",
    text: `Palash is by far one of the best frontend developers I've had the pleasure to work with. He's ability to understand complex problems and solve them is impressive. He's a great team player that knows how to communicate and follow instructions. He has a great understanding of UI/UX and he's always looking to improve and learn. His attention to details is exceptional. During the time I worked with him, he took the responsibility of running our retros in our agile sprints and he ran the best retros I have ever attended to. Palash would be an amazing asset to any company. I'd love to work with him again in the future.`,
    info: "April 12, 2022",
  },
  {
    id: 25,
    pictureUrl: "aditya.jpeg",
    by: "Aditya Wardhana",
    designation: "Software Developer, SITECH Construction Systems",
    text: `Palash is one of the best Front End Developer I had pleasure working with. Truly a quick learner as he was able to understand a very complex project, were able to be productive and provide advice in a short amount of time.`,
    info: "September 14, 2021",
  },
  {
    id: 24,
    pictureUrl: "thomas.jpeg",
    by: "Thomas Sedl",
    designation: "Software Developer, BuildingPoint Australia",
    text: `Palash was a great addition to the CivilPro team. He is a good Front End Developer and learnt our product quickly. I would happily work with him again.`,
    info: "August 3, 2021",
  },
  {
    id: 23,
    pictureUrl: "marc.jpeg",
    by: "Marc Gerada",
    designation: "Information Technology Manager, Brisbane Capital",
    text: `Palash worked efficiently and quickly, each task was completed within days, not weeks and the overall outcome of the product was excellent, very happy with our final finished website now!`,
    info: "January 23, 2020",
  },
  {
    id: 22,
    pictureUrl: "ankit.jpeg",
    by: "Ankit Rohilla",
    designation: "CSM certified Scrum Practitioner at Fidelity International",
    text: `Palash joined my organisation as a fresher 2 years back and since day one, his commitment to work had always been commendable. 'Going the extra mile' is his unique trait that make him one of the most valuable asset for any team. He works on tasks with minimal guidance, always asks relevant questions and provide relevant suggestions. His clarity of thought process, curiosity to explore new modules and his display of discipline has helped us a great deal in finishing complex functional projects with great quality. Apart from his regular project work, he has also shown great commitment in recreating team's collaboration site and it received remarkable comments from major stakeholders. He also helped us in revamping the look-n-feel of existing monotonous sites using latest technologies and developed new applications like REST API Invocation Utility, Build Radiators, etc.`,
    info: "May 10, 2019",
  },
  {
    id: 21,
    pictureUrl: "roma.jpeg",
    by: "Roma Dhar",
    designation: "Business Analysis/ Project Management Professional",
    text: `Palash possesses a great combination of skills, temperament, creativity and technical know-how that makes him a great asset to any team/organisation he may choose to be with. In today's world where there is a huge focus on EQ and not just IQ, Palash fits that bill very well. He is high on EQ as well as IQ. Palash was instrumental in spear-heading some key portfolio level initiatives that focussed on increasing business knowledge in the teams, communication strategy and team building/fun-at-work. He did a fabulous job on all of these initiatives ; and at the same time learnt and worked on cutting edge technologies in customer experience space. At the early stage in his career, Palash has demonstrated great maturity, professionalism, drive for excellence and productiveness. Combining these qualities with his positive disposition and people skills, he has a great future ahead of him.`,
    info: "February 17, 2019",
  },
  {
    id: 20,
    pictureUrl: "mohit.jpeg",
    by: "Mohit Goyal",
    designation: "Technical Specialist at Fidelity International",
    text: `It was great to work together with Palash. His hands-on approach in development and behavior won him respect of everyone. He is always capable of adapting new working environment. Loyal, independent and has a positive attitude towards work.`,
    info: "May 10, 2019",
  },
  {
    id: 19,
    pictureUrl: "shubham.jpeg",
    by: "Shubham Chitkara",
    designation: "Senior Project Manager at Fidelity International",
    text: `Palash worked me on developing a mobile app for our Global DC business. Though new to the technology and domain, his commitment to learn and pick-up stuff , agile mindset to ever changing complex UI requirements, open to feedback and incorporating them in the UI designs overnight were key highlights for me. He brought a tremendous wealth of above qualities to the table. Though he was quite new to the team,soon the whole team looked up to him. He was recognized for his collaboration with teams across the globe in UK,US, Dublin and HK. It was an absolute pleasure working with him and I wish him all the best for his future.`,
    info: "March 3, 2019",
  },
  {
    id: 18,
    pictureUrl: "mykolas.jpeg",
    by: "Mykolas Padolskis",
    designation: "Solutions Strategy Associate at LGIM",
    text: `I worked with Palash on developing both internal and client facing websites and apps for Fidelity’s Workplace Investing business. He is a creative, diligent and proactive developer, who has lots of good ideas and works hard to implement them. It was a pleasure working with him and I believe he has a bright future ahead of him.`,
    info: "February 17, 2019",
  },
  {
    id: 17,
    pictureUrl: "avik.jpeg",
    by: "Avik Deb",
    designation: "Senior Manager, Engineering Practices",
    text: `I have worked with Palash for more than a year in a customer focus large financial project. The challenge was to enhance the customer experience with rich UI and to make a visually compelling yet easy to navigate website. This was absolutely important for the business to grow and sustain. Palash showed his technical acumen blended with excellent sense of visual arts to achieve this goal. The outcome was positive. apart from this, I found Palash an extremely amiable and social person that make him a perfect team player. He has always been eager to take new challenges and never been reluctant to learn new things. His recent work on mobile interface of the same product got appreciated by the stakeholders as well. I would strongly recommend him and surely he will be an asset to any team!`,
    info: "February 17, 2019",
  },
  {
    id: 16,
    pictureUrl: "martina.jpeg",
    by: "Martina Pagliaro",
    designation: "Head of Product Management Consulting at BJSS",
    text: `Palash is a pleasure to work with. He is responsible and hard-working. Palash is always happy to walk the extra mile to achieve the set objectives. He became the Lead UI Developer for the project we were working together, being the go-to person for any UI related problem or question, trusted by the scrum team and all stakeholders. I cannot recommend Palash enough and I definitely hope we cross paths in the future again.`,
    info: "February 14, 2019",
  },
  {
    id: 15,
    pictureUrl: "pratik.jpeg",
    by: "Pratik Prakash",
    designation: "Master Software Engineer (SDE-IV)",
    text: `Palash is one of few people who can achieve success due to their diligence. While working at Fidelity ,Palash has shown marvellous learning capability and creative value addition to the organization specially around business user experience. One of the most important attribute he owns is willingness to listen , learn. I know Plash will be an asset to every organization . I wish him all the best for future endeavour.`,
    info: "February 13, 2019",
  },
  {
    id: 14,
    pictureUrl: "charu.jpeg",
    by: "Charu Upadhyay",
    designation: "Associate Software Engineer at JPMorgan Chase & Co.",
    text: `Palash is a very dedicated professional. He has excellent learning capabilities, his never say no attitude brings so much positivity for any work. He is a very reliable person, you can assign any task and work will be done without any chaser, which is the best quality to look for. Keep up the good work Palash.`,
    info: "February 11, 2019",
  },
  {
    id: 13,
    pictureUrl: "naresh.jpeg",
    by: "Naresh Pagadala",
    designation: "Associate Director at Fidelity International",
    text: `Palash is a gem of person when it comes to work. I could see his transformation from a graduate programmer to a matured UI developer over a span of 2 years which is really commendable. His dedication, commitment, positive attitude and hard work make him stand apart from others. Way to go Palash.`,
    info: "February 5, 2019",
  },
  {
    id: 12,
    pictureUrl: "rohit.jpeg",
    by: "Rohit Nehra",
    designation: "Automation Consultant",
    text: `Palash is a gem of person when it comes to work. I could see his transformation from a graduate programmer to a matured UI developer over a span of 2 years which is really commendable. His dedication, commitment, positive attitude and hard work make him stand apart from others. Way to go Palash.`,
    info: "February 3, 2019",
  },
  {
    id: 11,
    pictureUrl: "mohitG.jpeg",
    by: "Mohit Gairola",
    designation: "Software Development Engineer at Publicis Sapient",
    text: `It was pleasure to work with Palash in one of the biometrics implementation assignments in Fidelity. Palash was known for his hard work and fast learning capability in the team.I found him very calm and composed through out the project. He has a very positive attitude and I have never heard anything negative from him. He will always be an asset for the team he will be working for. Wish him all the best for all his future endeavours..!!`,
    info: "February 3, 2019",
  },
  {
    id: 10,
    pictureUrl: "vinnie.jpeg",
    by: "Vinnie Bhardwaj",
    designation: "Staff Engineer at Nagarro",
    text: `Palash has been one silent determined person who never gives up on things until he gets them done. You will see his name appearing every now and then in company newsletters and leaderboards for appreciation of his work and him getting rewards and appreciation. He is one such person who will get himself distinguished amongst tens of thousands of people for his talent and hardwork. His witty nature acts like an icing on the cake when he describes his creative work. I would highly recommend palash for every role knowing he will make out his own beautiful way inspiring others too.`,
    info: "February 1, 2019",
  },
  {
    id: 9,
    pictureUrl: "sahil.jpeg",
    by: "Sahil Garg",
    text: `Working with Palash was a memorable and great learning experience for me. Ideas, feedback and suggestion provided by Palash not only helped in exploring new angles but also gave better insights. Dedication, out of the box thinking, creativity, commitment, zeal to learn, team player, multitasker, enthusiastic, optimistic, etc are some of the characteristics that can define him the best. Wish him all the very best for his future endeavours.`,
    info: "February 1, 2019",
  },
  {
    id: 8,
    pictureUrl: "sahana.jpeg",
    designation: "Programmer at Fidelity International",
    by: "Sahana Muquit",
    text: `Hi Palash, working with you in FIL was really amazing. You have been a mentor to me. I always appreciate your quality of work and your dedication towards it. May you excel in your career and become a successful person.`,
    info: "January 31, 2019",
  },
  {
    id: 7,
    pictureUrl: "vineet.jpeg",
    designation: "Lead UI/UX Designer",
    by: "Vineet Gupta",
    text: `I got a chance to work with Palash when we were in Google Students' Club, NCU. His passionate and dedicated working style was a driving force for the team. He is a good listener and always have out of box ideas with good factual backing. He emerged from subject matter expert to torchbearer for the team in no time. He knows what to do and when which makes him an indispensable part of any team/organization. I strongly recommend him and wish him all the success at his work.`,
    info: "September 18, 2018",
  },
  {
    id: 6,
    pictureUrl: "mehak.jpeg",
    designation: "Cyber Security analyst",
    by: "Dr. Mehak Khurana",
    text: `Palash is hard working and developed a hardware-based project for blind students and has never failed to add a creative touch when appropriate. As a pragmatic leader and a realist, he can grasp ideas in a holistic manner and still pay attention to minor details. He is known as diligent and personable. He is extremely enthusiastic about his work. He always has a creative, positive outlook and he’s good at organizing and bringing people together. His presentation style is excellent. I would highly recommend him for any position, as he naturally rises to the occasion when presented with something he is passionate about.`,
    info: "July 28, 2016",
  },
  {
    id: 5,
    pictureUrl: "artika.jpeg",
    designation: "Solution Advisor at Deloitte India",
    by: "Artika Manchanda",
    text: `Palash is perspicacious, analytically sound and handles stress situations very calmly. I know Palash as a hard 'Task-Master' who manages the work assigned to him timely and excellently. He has impressive presentation skills, good work ethics and integrity. Palash, as a Google Students' Ambassador has managed to bring together several unique talents, making him one of the most dependable and valuable member of the team. His passion towards any task he takes up allows him create an impact that nobody else can. It has always been a delight to learn from him and seek his guidance. Wishing you all the best for future endeavors!.`,
    info: "June 28, 2016",
  },
  {
    id: 4,
    pictureUrl: "yasir.jpeg",
    designation: "Google Partner Academy Trainer",
    by: "Yasir Zahoor Rather",
    text: `I had the privilege of working with Palash during Google Online Marketing Lab 2014. Palash is proactive, result oriented, responsible person and he is always ready to put all his energy and time to get the job done. I trained on SEO in the same Lab & I can say that Palash is quick learner & a good improviser.`,
    info: "June 27, 2016",
  },
  {
    id: 3,
    pictureUrl: "chirag.jpeg",
    designation: "SDE2 at Flipkart",
    by: "Chirag Agarwal",
    text: `Palash is a humble, cool headed person, and is pleasing to interact with. With his quick learning skill and innate ability to lead a team, I find a bright future for him in any future endeavour. It was a pleasure to work with Palash and would love to do the same in future if our destiny intersects.`,
    info: "June 23, 2016",
  },
  {
    id: 2,
    pictureUrl: "latika.jpeg",
    designation: "Professor at Sushant University",
    by: "Latika Duhan",
    text: `Palash is a very dynamic and committed student. He was leading Google's Student Club at University, and the club did really well under his leadership. He has been a very sincere student, and did very innovative projects. I wish him all the success.`,
    info: "June 22, 2016",
  },
  {
    id: 1,
    pictureUrl: "rashi.jpeg",
    designation: "Data Engineer at Amazon",
    by: "Rashi Aggarwal",
    text: `I have worked with Palash in his Google Student's club for around 2 years during college. He has good interpersonal skills and I found him very enthusiastic towards his work. Apart from all this, he is very helpful towards everyone and a lot of things can be learnt from him. He has been my classmate during b.tech. One quality of him that inspires me a lot is that he completes every work with perfection. I see him as a good leader and person.`,
    info: "June 18, 2016",
  },
];
