/* eslint-disable jsx-a11y/anchor-is-valid */
import FsLightbox from "fslightbox-react";
import React, { useState } from "react";
import { artsListData } from "shared/data/art-page.data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faHeart } from "@fortawesome/free-solid-svg-icons";
import { Scrollbar } from "smooth-scrollbar-react";

const ArtPage = (props) => {
  const artData = artsListData;
  //project image toggler opens avatar lightbox
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });
  const artSources = artData.map((data) => {
    return require(`assets/img/art/${data.imagePath}`).default;
  });
  function openLightBoxSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }
  return (
    <>
      {" "}
      <div
        className={`webcv-content ${props.isDisabled ? "webcv-active" : ""}`}
      >
        <div className="webcv-curtain" onClick={props.closeAllPanels}></div>{" "}
        <div className="webcv-top-bg">
          <div className="webcv-top-bg-overlay"></div>
        </div>
        <Scrollbar
          damping={0.2}
          renderByPixels={true}
          continuousScrolling={true}
          className="webcv-scroll-frame"
        >
          <div className="container-fluid">
            {" "}
            <div className="row p-30-0">
              <div className="col-lg-12">
                <div className="webcv-section-title">
                  <div className="webcv-title-frame">
                    <h4>Recent Art Projects</h4>
                  </div>
                  <div className="webcv-right-frame">
                    <div className="webcv-project-category">
                      Handmade with{" "}
                      <FontAwesomeIcon
                        className="webcv-item-hover"
                        icon={faHeart}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="webcv-grid webcv-grid-3-col webcv-gallery">
                {artData.map((data, index) => (
                  <div key={data.id} className="webcv-grid-item">
                    <div className="d-contents">
                      <span
                        className="
                            webcv-a webcv-portfolio-item-frame webcv-square"
                      >
                        <a
                          role="button"
                          onClick={() => openLightBoxSlide(index + 1)}
                          className="d-contents"
                        >
                          <img
                            src={
                              require(`assets/img/art/${data.imagePath}`)
                                .default
                            }
                            alt="ArtImage"
                          />
                          <FontAwesomeIcon
                            className="webcv-item-hover"
                            icon={faExpand}
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Scrollbar>
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={artSources}
          slide={lightboxController.slide}
        />
      </div>
    </>
  );
};

export default ArtPage;
