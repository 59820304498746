export const projectTypes = {
  all: { id: 0, type: "all", name: "All" },
  webapplication: { id: 1, type: "webapplication", name: "Web Application" },
  design: { id: 2, type: "design", name: "Designs" },
  angularApps: { id: 3, type: "angularApps", name: "Angular Application" },
  reactApps: { id: 4, type: "reactApps", name: "React Application" },
  research: { id: 5, type: "research", name: "Research Project" },
  posters: { id: 6 },
};

export const projectsListData = [
  {
    id: 16,
    projectID: 16,
    projectImagePath: "KROP_Thumbnail.png",
    projectName: "KROP Consulting",
    projectDescription:
      "Angular website for KROP consulting to give information about their services",
    projectType: projectTypes.angularApps,
    hasDetails: true,
    projectDetails: {
      imageThumbnailPath: "KROP_gifnail.gif",
      imageFullPath: `https://www.youtube.com/watch?v=MmubWU0eBpY`,
      title: "KROP Consulting",
      longDescription:
        "Created a static Angular website for KROP Consulting, Australia to establish their online presence.",
      deployURL: "https://kropconsulting.com.au",
      techStack: "Angular, Typescript, HTML, SCSS",
      organisation: "KROP Consulting",
      recommendationsExist: false,
    },
  },
  {
    id: 15,
    projectID: 15,
    projectImagePath: "BC_Thumbnail.png",
    projectName: "Brisbane Capital",
    projectDescription:
      "Website for Brisbane Capital to provide loans information",
    projectType: projectTypes.webapplication,
    hasDetails: true,
    projectDetails: {
      imageThumbnailPath: "BC_gifnail.gif",
      imageFullPath: `https://www.youtube.com/watch?v=Yemo5gGHXRQ`,
      title: "Brisbane Capital",
      longDescription:
        "Successfully revamped and hosted Brisbane Capital's Website as part of their digital revamp strategy.",
      deployURL: "https://brisbanecapital.com.au",
      techStack: "Angular, Boostrap, HTML, SCSS",
      organisation: "Bribane Capital",
      recommendationsExist: true,
      recommendations: [
        {
          id: 3,
          pictureUrl: "marc.jpeg",
          by: "Marc Gerada",
          designation: "Information Technology Manager, Brisbane Capital",
          text: `Palash worked efficiently and quickly, each task was completed within days, not weeks and the overall outcome of the product was excellent, very happy with our final finished website now!`,
          info: "January 23, 2020",
        },
      ],
    },
  },
  {
    id: 14,
    projectID: 14,
    projectImagePath: "BC_apply.png",
    projectName: "BC Application Form",
    projectDescription:
      "Application Form for Brisbane Capital to allow users to apply for loans",
    projectType: projectTypes.angularApps,
    hasDetails: true,
    projectDetails: {
      imageThumbnailPath: "BC_applygifnail.gif",
      imageFullPath: `https://www.youtube.com/watch?v=Qg3xQ6h8r-M`,
      title: "BC Application Form",
      longDescription:
        "Application Form for Brisbane Capital to allow users to apply for loans",
      deployURL: "https://brisbanecapital.com.au/apply",
      techStack: "Angular, Typescript, HTML, SCSS",
      organisation: "Bribane Capital",
      recommendationsExist: true,
      recommendations: [
        {
          id: 1,
          pictureUrl: "anthony.jpeg",
          by: "Anthony Gerada",
          designation: "Founder / Director, Brisbane Capital",
          text: `Palash has completed some front-end web development for us which we are very happy with. Palash is professional and honest in his dealings.`,
          info: "February 26, 2022",
        },
      ],
    },
  },
  {
    id: 13,
    projectID: 13,
    projectImagePath: "Cuvva.png",
    projectName: "React Star Wars API Frontend",
    projectDescription:
      "React - Redux application Frontend for ingesting the Star Wars API",
    projectType: projectTypes.reactApps,
    hasDetails: true,
    projectDetails: {
      imageThumbnailPath: "Cuvva.gif",
      imageFullPath: `https://www.youtube.com/watch?v=uYqO4ZxzpV4`,
      title:
        "React - Redux application Frontend for ingesting the Star Wars API",
      longDescription:
        "Simple website for the Star Wars API with the ability to view all root types and resources inside them. It uses Redux to manage application state and Saga as a middleware with Typescript for type checking.",
      deployURL: "https://palash-starwars.netlify.app/",
      techStack: "React, Redux, Typescript",
      organisation: "Cuvva",
      recommendationsExist: false,
    },
  },
  {
    id: 12,
    projectID: 12,
    projectImagePath: "Vinyoga.png",
    projectName: "Vinyasa Yoga Studio Website",
    projectDescription: "Website for Vinyasa Yoga Studio",
    projectType: projectTypes.webapplication,
    hasDetails: true,
    projectDetails: {
      imageThumbnailPath: "Vinyoga_gifnail.gif",
      imageFullPath: `https://www.youtube.com/watch?v=arH0R5cIoGM`,
      title: "Vinyasa Yoga Studio Website",
      longDescription:
        "Static Website for Vinyasa Yoga Studio to promote local business",
      deployURL: "https://www.vinyoga.in/",
      techStack: "HTML, CSS, Javascript, Wordpress",
      organisation: "Vinyasa Yoga Studio",
      recommendationsExist: false,
    },
  },
  {
    id: 11,
    projectID: 11,
    projectImagePath: "Swades.png",
    projectName: "Swades Foods Ecommerce Website",
    projectDescription: "E-commerce website for Swades Foods",
    projectType: projectTypes.webapplication,
    hasDetails: true,
    projectDetails: {
      imageThumbnailPath: "Swades_gifnail.gif",
      imageFullPath: `https://www.youtube.com/watch?v=-ecnhGZdkTA`,
      title: "Swades Foods Ecommerce Website",
      longDescription:
        "An e-commerce platform for Swades Foods to facilitate online shopping for Indian grocery and food products.",
      deployURL: "https://swadesfoods.com.au/",
      techStack: "HTML, CSS, Javascript, Wordpress",
      organisation: "Swades Foods",
      recommendationsExist: false,
    },
  },
  {
    id: 10,
    projectID: 10,
    projectImagePath: "GTS.png",
    projectName: "Global Translation Platform",
    projectDescription:
      "A platform to translate and review books in different languages",
    projectType: projectTypes.reactApps,
    hasDetails: true,
    projectDetails: {
      imageThumbnailPath: "GTS_gifnail.gif",
      imageFullPath: `https://www.youtube.com/watch?v=OIoj1PTmz3U`,
      title: "Global Translation Platform",
      longDescription:
        "Global Translation Platform (GTS) is as a prototype application that consists of a workflow to create, review and publish translations of books in multiple languages which is the data set of Library For All (Australia). The platform allows the organisation to expand its current digital library in multiple languages and improve the accuracy of book translations. The platform aims to form a community of translators and users proficient in multiple languages who would like to utilise their skills and translate and review books for Library For All.",
      deployURL: "",
      techStack: "React, HTML, CSS, Node",
      organisation: "Library For All",
      recommendationsExist: false,
    },
  },
  {
    id: 9,
    projectID: 9,
    projectImagePath: "Shikshak.png",
    projectName: "Shikshak : A Tutor System for the blind",
    projectDescription:
      "Research project on developing a tutor sytem for blind students",
    projectType: projectTypes.research,
    hasDetails: true,
    projectDetails: {
      title: "Shikshak : A Tutor System for the blind",
      longDescription: `Less than 3% of the 145 million blind people living in developing countries are literate. This low literacy
      rate is partly due to the lack of trained teachers and the challenges associated with learning Braille on a traditional slate
      and stylus. The solution to this problem is to develop a prototype of a cost effective and user friendly device, capable of
      facilitating Braille tutoring among the blind. The proposed device aims to incorporate tactile, audio and visual output for
      the user that can be used for both self and peer-to-peer learning. The prime feature of this -system is 3D pattern formation
      in Braille language for different alphabets and numbers to facilitate Braille learning through a sense of touch.
      This device opens up the possibility of an adult who does not have formal Braille training but can assist and help the blind
      student to learn Braille and also assess their learning levels. This paper provides an overall survey about problems faced
      using conventional methods for teaching and an insight into developing an effective tutor system for the blind`,
      deployURL: "https://www.ijcsmc.com/docs/papers/March2017/V6I3201716.pdf",
      recommendationsExist: false,
    },
  },
  {
    id: 8,
    projectID: 8,
    projectImagePath: "UKStrategy.png",
    projectName: "Strategy Roadmap Website",
    projectDescription: "Website to define the roadmap for the business",
    projectType: projectTypes.webapplication,
    hasDetails: true,
    projectDetails: {
      title: "Strategy Roadmap Website",
      longDescription: `Website to define the roadmap and strategy for a business`,
      deployURL: "",
      imageThumbnailPath: "UKStrategy_gifnail.gif",
      imageFullPath: `https://www.youtube.com/watch?v=y8VITVXxoWI`,
      techStack: "Bootstrap, HTML, CSS, Javascript",
      organisation: "Fidelity International",
      recommendationsExist: true,
      recommendations: [
        {
          id: 7,
          pictureUrl: "mykolas.jpeg",
          by: "Mykolas Padolskis",
          designation: "Solutions Strategy Associate at LGIM",
          text: `I worked with Palash on developing both internal and client facing websites and apps for Fidelity’s Workplace Investing business. He is a creative, diligent and proactive developer, who has lots of good ideas and works hard to implement them. It was a pleasure working with him and I believe he has a bright future ahead of him.`,
          info: "February 17, 2019",
        },
        {
          id: 9,
          pictureUrl: "roma.jpeg",
          by: "Roma Dhar",
          designation: "Business Analysis/ Project Management Professional",
          text: `Palash possesses a great combination of skills, temperament, creativity and technical know-how that makes him a great asset to any team/organisation he may choose to be with. In today's world where there is a huge focus on EQ and not just IQ, Palash fits that bill very well. He is high on EQ as well as IQ. Palash was instrumental in spear-heading some key portfolio level initiatives that focussed on increasing business knowledge in the teams, communication strategy and team building/fun-at-work. He did a fabulous job on all of these initiatives ; and at the same time learnt and worked on cutting edge technologies in customer experience space. At the early stage in his career, Palash has demonstrated great maturity, professionalism, drive for excellence and productiveness. Combining these qualities with his positive disposition and people skills, he has a great future ahead of him.`,
          info: "February 17, 2019",
        },
        {
          id: 12,
          pictureUrl: "charu.jpeg",
          by: "Charu Upadhyay",
          designation: "Associate Software Engineer at JPMorgan Chase & Co.",
          text: `Palash is a very dedicated professional. He has excellent learning capabilities, his never say no attitude brings so much positivity for any work. He is a very reliable person, you can assign any task and work will be done without any chaser, which is the best quality to look for. Keep up the good work Palash.`,
          info: "February 11, 2019",
        },
        {
          id: 16,
          pictureUrl: "vinnie.jpeg",
          by: "Vinnie Bhardwaj",
          designation: "Staff Engineer at Nagarro",
          text: `Palash has been one silent determined person who never gives up on things until he gets them done. You will see his name appearing every now and then in company newsletters and leaderboards for appreciation of his work and him getting rewards and appreciation. He is one such person who will get himself distinguished amongst tens of thousands of people for his talent and hardwork. His witty nature acts like an icing on the cake when he describes his creative work. I would highly recommend palash for every role knowing he will make out his own beautiful way inspiring others too.`,
          info: "February 1, 2019",
        },
      ],
    },
  },
  {
    id: 7,
    projectID: 7,
    projectImagePath: "Vrindavan.png",
    projectName: "Vrindavan Marketing Website",
    projectDescription:
      "Static Website to set up a digital presence for Vrindavan Marketing",
    projectType: projectTypes.webapplication,
    hasDetails: true,
    projectDetails: {
      imageThumbnailPath: "Vrindavan_gifnail.gif",
      imageFullPath: `https://www.youtube.com/watch?v=7Il-kkyXjCU`,
      title: "Vrindavan Marketing Website",
      longDescription:
        "Static Website to set up a digital presence for Vrindavan Marketing",
      deployURL: "",
      techStack: "Bootstrap, HTML, CSS",
      organisation: "Vrindavan Marketing",
      recommendationsExist: false,
    },
  },
  {
    id: 6,
    projectID: 6,
    projectImagePath: "Idant.png",
    projectName: "Idant Learning Solutions Website",
    projectDescription:
      "Static Website to set up a digital presence for Idant Learning Solutions",
    projectType: projectTypes.webapplication,
    hasDetails: true,
    projectDetails: {
      imageThumbnailPath: "Idant_gifnail.gif",
      imageFullPath: `https://www.youtube.com/watch?v=-5MaJrdCtLE`,
      title: "Idant Learning Solutions Website",
      longDescription:
        "Static Website to set up a digital presence for Idant Learning Solutions",
      deployURL: "",
      techStack: "Bootstrap, HTML, CSS",
      organisation: "Idant Learning Solutions",
      recommendationsExist: true,
      recommendations: [
        {
          id: 14,
          pictureUrl: "rohit.jpeg",
          by: "Rohit Nehra",
          designation: "Automation Consultant",
          text: `Palash is a gem of person when it comes to work. I could see his transformation from a graduate programmer to a matured UI developer over a span of 2 years which is really commendable. His dedication, commitment, positive attitude and hard work make him stand apart from others. Way to go Palash.`,
          info: "February 3, 2019",
        },
      ],
    },
  },
  {
    id: 5,
    projectID: 5,
    projectImagePath: "PensionApp.png",
    projectName: "Pensions Mobile Application",
    projectDescription:
      "Hyrbid Mobile Application to manage pensions for UK customers",
    projectType: projectTypes.angularApps,
    hasDetails: true,
    projectDetails: {
      imageThumbnailPath: "PensionApp.png",
      imageFullPath: `https://www.youtube.com/watch?v=BiO5RvphgtI`,
      title: "Pensions Mobile Application",
      longDescription:
        "The objective of developing a Global Mobile App was to maximise technology re-use across two separate legal organisations titled Fidelity Investments and Fidelity International and provide a more consistent and integrated mobile experience to multinational clients and allow easy access for the clients to see their pension balances, salary contributions to the pension funds and other features using a mobile application for IOS and Android users in the UK region, with a target audience of 500000 (Five Hundred Thousand) with in the first year of the release.",
      deployURL: "",
      techStack: "Angular, Ionic, Cordova",
      organisation: "Fidelity International",
      recommendationsExist: true,
      recommendations: [
        {
          id: 6,
          pictureUrl: "shubham.jpeg",
          by: "Shubham Chitkara",
          designation: "Senior Project Manager at Fidelity International",
          text: `Palash worked me on developing a mobile app for our Global DC business. Though new to the technology and domain, his commitment to learn and pick-up stuff , agile mindset to ever changing complex UI requirements, open to feedback and incorporating them in the UI designs overnight were key highlights for me. He brought a tremendous wealth of above qualities to the table. Though he was quite new to the team,soon the whole team looked up to him. He was recognized for his collaboration with teams across the globe in UK,US, Dublin and HK. It was an absolute pleasure working with him and I wish him all the best for his future.`,
          info: "March 3, 2019",
        },
        {
          id: 8,
          pictureUrl: "avik.jpeg",
          by: "Avik Deb",
          designation: "Senior Manager, Engineering Practices",
          text: `I have worked with Palash for more than a year in a customer focus large financial project. The challenge was to enhance the customer experience with rich UI and to make a visually compelling yet easy to navigate website. This was absolutely important for the business to grow and sustain. Palash showed his technical acumen blended with excellent sense of visual arts to achieve this goal. The outcome was positive. apart from this, I found Palash an extremely amiable and social person that make him a perfect team player. He has always been eager to take new challenges and never been reluctant to learn new things. His recent work on mobile interface of the same product got appreciated by the stakeholders as well. I would strongly recommend him and surely he will be an asset to any team!`,
          info: "February 17, 2019",
        },
        {
          id: 10,
          pictureUrl: "martina.jpeg",
          by: "Martina Pagliaro",
          designation: "Head of Product Management Consulting at BJSS",
          text: `Palash is a pleasure to work with. He is responsible and hard-working. Palash is always happy to walk the extra mile to achieve the set objectives. He became the Lead UI Developer for the project we were working together, being the go-to person for any UI related problem or question, trusted by the scrum team and all stakeholders. I cannot recommend Palash enough and I definitely hope we cross paths in the future again.`,
          info: "February 14, 2019",
        },
        {
          id: 11,
          pictureUrl: "pratik.jpeg",
          by: "Pratik Prakash",
          designation: "Master Software Engineer (SDE-IV)",
          text: `Palash is one of few people who can achieve success due to their diligence. While working at Fidelity ,Palash has shown marvellous learning capability and creative value addition to the organization specially around business user experience. One of the most important attribute he owns is willingness to listen , learn. I know Plash will be an asset to every organization . I wish him all the best for future endeavour.`,
          info: "February 13, 2019",
        },
        {
          id: 13,
          pictureUrl: "naresh.jpeg",
          by: "Naresh Pagadala",
          designation: "Associate Director at Fidelity International",
          text: `Palash is a gem of person when it comes to work. I could see his transformation from a graduate programmer to a matured UI developer over a span of 2 years which is really commendable. His dedication, commitment, positive attitude and hard work make him stand apart from others. Way to go Palash.`,
          info: "February 5, 2019",
        },
        {
          id: 15,
          pictureUrl: "mohitG.jpeg",
          by: "Mohit Gairola",
          designation: "Software Development Engineer at Publicis Sapient",
          text: `It was pleasure to work with Palash in one of the biometrics implementation assignments in Fidelity. Palash was known for his hard work and fast learning capability in the team.I found him very calm and composed through out the project. He has a very positive attitude and I have never heard anything negative from him. He will always be an asset for the team he will be working for. Wish him all the best for all his future endeavours..!!`,
          info: "February 3, 2019",
        },
      ],
    },
  },
  {
    id: 4,
    projectID: 4,
    projectImagePath: "BloodConnect.gif",
    projectName: "Blood Connect Design Prototype",
    projectDescription:
      "High Fidelity Design Prototype for a Blood Donation Mobile Application",
    projectType: projectTypes.design,
    hasDetails: false,
  },
  {
    id: 3,
    projectID: 3,
    projectImagePath: "MZaara.png",
    projectName: "M-Zaara Boutique Website",
    projectDescription:
      "Static Website to set up a digital presence for M-Zaara Boutique",
    projectType: projectTypes.webapplication,
    hasDetails: true,
    projectDetails: {
      imageThumbnailPath: "MZaara_gifnail.gif",
      imageFullPath: `https://www.youtube.com/watch?v=0sBmkkRemak`,
      title: "M-Zaara Boutique Website",
      longDescription:
        "Static Website to set up a digital presence for M-Zaara Boutique",
      deployURL: "",
      techStack: "Bootstrap, HTML, CSS",
      organisation: "M-Zaara Boutique",
      recommendationsExist: false,
    },
  },
  {
    id: 2,
    projectID: 2,
    projectImagePath: "Stocks.png",
    projectName: "React Stocks Application",
    projectDescription:
      "Application to view stocks prices with filters and pagination",
    projectType: projectTypes.reactApps,
    hasDetails: true,
    projectDetails: {
      imageThumbnailPath: "Stocks_gifnail.gif",
      imageFullPath: `https://www.youtube.com/watch?v=MfGiOAfN1cw`,
      title: "React Stocks Application",
      longDescription:
        "Application to view stocks prices with filters and pagination",
      deployURL: "",
      techStack: "React, Bootstrap, JSX",
      organisation: "QUT",
      recommendationsExist: false,
    },
  },
  {
    id: 1,
    projectID: 1,
    projectImagePath: "StudentEngagement.png",
    projectName: "Improving Student Engagement using Gamification",
    projectDescription:
      "Design considerations for using gamification to promote student engagement in project-based units",
    projectType: projectTypes.research,
    hasDetails: true,
    projectDetails: {
      title:
        "Design considerations for using gamification to promote student engagement in project-based units",
      longDescription: `This exploratory study examines how gamification affects student engagement in the context of project-based units undertaken in Australian universities. By studying the concept of gamification with the self-determination theory of motivation, we investigate how it can be used to increase intrinsic student motivation. A survey was developed and rolled out to 267 students enrolled in a project unit to explore the impact of factors such as sense of belonging, autonomy and competence on student engagement, and how meaningful gamification can be used to fulfil these factors presented in the self-determination theory of motivation. The results from quantitative analysis illustrate perceptions from different student cohorts that could promote their engagement in a project unit. The findings are presented in the form of design recommendations that can serve as a guideline for the course designers and developers of the ICT platforms on how to use gamification to promote student engagement in project-based units.`,
      deployURL: "",
      recommendationsExist: false,
    },
  },
];
