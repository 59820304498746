import { routePaths } from "shared/data/app-routes.data";
export const navigationMenu = [
  {
    id: 1,
    name: routePaths.home.pathName,
    redirectPath: routePaths.home.path,
    hasChildren: false,
    children: [],
    isDefaultRoute: true,
  },
  {
    id: 2,
    name: routePaths.history.pathName,
    redirectPath: routePaths.history.path,
    hasChildren: false,
    children: [],
  },
  {
    id: 3,
    name: routePaths.projects.pathName,
    redirectPath: routePaths.projects.path,
    hasChildren: false,
    children: [],
  },
  {
    id: 4,
    name: routePaths.interests.pathName,
    hasChildren: true,
    children: [
      {
        id: 4.1,
        name: routePaths.interests.path.art.pathname,
        redirectPath: routePaths.interests.path.art.path,
      },
      {
        id: 4.2,
        name: routePaths.interests.path.handlettering.pathname,
        redirectPath: routePaths.interests.path.handlettering.path,
      },
      {
        id: 4.3,
        name: routePaths.interests.path.photography.pathname,
        redirectPath: routePaths.interests.path.photography.path,
      },
      {
        id: 4.4,
        name: routePaths.interests.path.writeups.pathname,
        redirectPath: routePaths.interests.path.writeups.path,
      },
    ],
  },
  {
    id: 5,
    name: routePaths.contact.pathName,
    redirectPath: routePaths.contact.path,
    hasChildren: false,
    children: [],
  },
];
