/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { getWriteUps } from "shared/providers/writeups-provider";

const WritingPage = (props) => {
  const history = useHistory();
  const writingsListData = getWriteUps();
  function seeDetails(url) {
    return history.push(url);
  }

  return (
    <>
      <div
        className={`webcv-content ${props.isDisabled ? "webcv-active" : ""}`}
      >
        <div className="webcv-curtain" onClick={props.closeAllPanels}></div>
        <div className="webcv-top-bg">
          <div className="webcv-top-bg-overlay"></div>
        </div>
        <Scrollbar
          damping={0.2}
          renderByPixels={true}
          continuousScrolling={true}
          className="webcv-scroll-frame"
        >
          <div className="container-fluid">
            {" "}
            <div className="row p-30-0">
              {writingsListData.map((writeup) => (
                <div key={writeup.id} className="col-lg-4 write-container">
                  <div className="webcv-a webcv-blog-card">
                    {/* post description */}
                    <div className="webcv-post-description">
                      {/* title */}
                      <h5 className="mb-15">{writeup.writeUpTitle}</h5>
                      <div className="mb-15">
                        {writeup.writeUpShortDescription}
                      </div>
                      {/* Link */}
                      <a
                        role="button"
                        onClick={() =>
                          seeDetails(`/writeups/${writeup.writeUpId}`)
                        }
                        className="webcv-link webcv-color-link webcv-w-chevron"
                      >
                        Read more
                        <span className="pl-1">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="webcv-w-chevron-tr"
                          />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Scrollbar>
      </div>
    </>
  );
};
export default WritingPage;
