import React from "react";
import { imageHeader } from "shared/data/landing-page.data";
import Typed from "react-typed";
import myImage from "assets/img/face-landing.png";
import { useHistory } from "react-router-dom";
import { routePaths } from "shared/data/app-routes.data";

const HeaderImage = () => {
  const history = useHistory();

  function handleProjectClick() {
    history.push(routePaths.projects.path);
  }
  return (
    <div className="container-fluid">
      {" "}
      <div className="row p-60-0 p-lg-30-0 p-md-15-0">
        {" "}
        <div className="col-lg-12">
          <div className="webcv-a webcv-banner">
            <div className="webcv-banner-back"></div>
            <div className="webcv-banner-overlay">
              <div className="webcv-banner-title">
                <h1 className="mb-15">{imageHeader.titleText}</h1>
                <div className="webcv-lg-text webcv-code mb-25">
                  &lt;<i>code</i>&gt;
                  {imageHeader.animatedTextCaption}{" "}
                  <Typed
                    strings={imageHeader.animatedTextSubstrings}
                    typeSpeed={40}
                    backSpeed={50}
                    loop
                  />
                  &lt;/<i>code</i>&gt;
                </div>
                <div className="webcv-buttons-frame">
                  {/*Redirect to projects*/}
                  <button
                    className="webcv-btn webcv-btn-md"
                    onClick={handleProjectClick}
                  >
                    <span>{imageHeader.redirectButtonText}</span>
                  </button>
                </div>
              </div>

              <img
                src={myImage}
                className="webcv-banner-photo"
                alt="Your Name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderImage;
