import React, { useState } from "react";
import myImage from "assets/img/face-home.png";
import FsLightbox from "fslightbox-react";

const ProfileHeader = (props) => {
  const { basicInfo } = props;
  //avatar toggler opens avatar lightbox
  const [avatarToggler, setAvatarToggler] = useState(false);

  function toggleAvatarBox() {
    //open the lightbox
    setAvatarToggler(!avatarToggler);
  }
  return (
    <div className="webcv-header">
      {/* profile image */}
      <div className="webcv-avatar">
        {/* lightbox for full screen avatar*/}
        <FsLightbox toggler={avatarToggler} sources={[myImage]} />
        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a
          role="button"
          onClick={toggleAvatarBox}
          className="webcv-avatar-curtain"
        >
          <img src={myImage} alt="avatar" />
        </a>
        <div className="webcv-lamp-light">
          {/* online icon */}
          <div className="webcv-available-lamp"></div>
        </div>
      </div>
      {/*avatar end*/}
      <h5 className="webcv-name mb-10">
        {/* redirect full profile link */}
        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a> {basicInfo.name} </a>
      </h5>
      {/* designation */}
      <div className="webcv-sm-text">{basicInfo.designation}</div>
    </div>
  );
};

export default ProfileHeader;
